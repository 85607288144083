import React, { Component } from "react";
import { Header, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class IntrinsicValue extends Component {
  // ** Function to return Intrinsic Value content

  render = () => {
    return (
      <div style={{ width: "95%", overflowY: "auto" }}>
        <Table definition id="iv" compact="very" celled color={"yellow"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="3"></Table.HeaderCell>
              <Table.HeaderCell>10 Year DCF Calculations</Table.HeaderCell>
              <Table.HeaderCell>20 Year DCF Calculations</Table.HeaderCell>
              <Table.HeaderCell>10 Year DE Calculations</Table.HeaderCell>
              <Table.HeaderCell>20 Year DE Calculations</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Company Name</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.compProfile.companyName}
                </Header>
              </Table.Cell>
              <Table.Cell>Present Value future Cash Flows</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDCFiv.pvOfFut
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.twentyDCFiv.pvOfFut
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDEiv.pvOfFut
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.twentyDEiv.pvOfFut
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Operating Cash Flow/Net Income (Last 4 Quarters)
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDCFiv.amountTobeProjected
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>Intrinsic Value before Cash/Debt</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDCFiv.intrinsicValueBeforeCashDebt
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.twentyDCFiv
                      .intrinsicValueBeforeCashDebt
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDEiv.intrinsicValueBeforeCashDebt
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.twentyDEiv
                      .intrinsicValueBeforeCashDebt
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total Debt (Short Term + Long Term)</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDCFiv.totalDebt
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>Debt per share (-)</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.tenDCFiv.dbtPrShr
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.twentyDCFiv.dbtPrShr
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.tenDEiv.dbtPrShr
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.twentyDEiv.dbtPrShr
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cash and Short Term Investments</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_long(
                    this.props.stockValues.tenDCFiv.totalCash
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>Avaiable Cash per share (+)</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.tenDCFiv.cshPrShr
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.twentyDCFiv.cshPrShr
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.tenDEiv.cshPrShr
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.twentyDEiv.cshPrShr
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cash Flow Growth Rate (1 - 5) years</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_percent(
                    this.props.stockValues.tenDCFiv.growhthRate_1_5
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell> Intrinsic Value</Table.Cell>
              <Table.Cell>
                <Header as="h3">
                  {Utils.format_currency_usd(
                    this.props.stockValues.tenDCFiv.fnlIntrinVal
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h3">
                  {Utils.format_currency_usd(
                    this.props.stockValues.twentyDCFiv.fnlIntrinVal
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h3">
                  {Utils.format_currency_usd(
                    this.props.stockValues.tenDEiv.fnlIntrinVal
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h3">
                  {Utils.format_currency_usd(
                    this.props.stockValues.twentyDEiv.fnlIntrinVal
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cash Flow Growth Rate (5 - 10) years</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {Utils.format_percent(
                    this.props.stockValues.tenDCFiv.growhthRate_5_10
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>Discount to current Price</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {Utils.format_percent(
                    this.props.stockValues.tenDCFiv.underValueDiscount
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {Utils.format_percent(
                    this.props.stockValues.twentyDCFiv.underValueDiscount
                  )}
                </Header>
              </Table.Cell>

              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.tenDEiv.underValueDiscount}%
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {Utils.format_percent(
                    this.props.stockValues.twentyDEiv.underValueDiscount
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cash Flow Growth Rate (10 - 20) years</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {Utils.format_percent(
                    this.props.stockValues.tenDCFiv.growhthRate_10_20
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>Current Price</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.quote.price
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.quote.price
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.quote.price
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_currency_usd(
                    this.props.stockValues.quote.price
                  )}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Shares Outstanding</Table.Cell>
              <Table.Cell>
                <Header as="h5">
                  {Utils.format_number_long(
                    this.props.stockValues.compProfile.sharesOutstanding
                  )}
                </Header>
              </Table.Cell>
              <Table.Cell>Current Year</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.tenDCFiv.currentYear}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.twentyDCFiv.currentYear}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.tenDEiv.currentYear}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.twentyDEiv.currentYear}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Beta</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.compProfile.beta}
                </Header>
              </Table.Cell>
              <Table.Cell>Dsicount Rate Used</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.tenDCFiv.discRt}%
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.twentyDCFiv.discRt}%
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.tenDEiv.discRt}%
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  {this.props.stockValues.twentyDEiv.discRt}%
                </Header>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  };
}
