import React, { useState } from "react";
import {
  Header,
  Image,
  Label,
  Segment,
  Table,
  Modal,
  Button,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockRadarChart from "./StockRadarChart";
import Utils from "../util/Utils";
import CompanyPage from "../company/CompanyPage";
import uuid from "react-uuid";

export default function CompareResults(props) {
  // **Function to return Stocks comparision results

  //console.log("CompareResults*********", props);
  const [stateValues, setstateValues] = useState({
    symbol: "",
    open: false,
  });

  function showModal(symbol) {
    setstateValues({
      symbol: symbol,
      open: true,
    });
  }

  return (
    <>
      <Table
        style={{ backgroundColor: "#FBF8EF" }}
        compact="very"
        collapsing
        celled
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Symbol
            </Table.HeaderCell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.HeaderCell
                key={props.stckLst[index].symbol}
                style={{ cursor: "pointer", backgroundColor: "#A9D0F5" }}
                onClick={() => showModal(props.stckLst[index].symbol)}
              >
                <Header as="h3" image>
                  <Image
                    src={props.stckLst[index].image}
                    rounded
                    style={{ mixBlendMode: "multiply" }}
                  />
                </Header>
                {props.stckLst[index].symbol}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Name
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.Cell
                key={props.stckLst[index].companyName.substring(0, 20)}
              >
                {props.stckLst[index].companyName.substring(0, 20)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Recommendation
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.Cell key={uuid()}>
                <Label
                  size="small"
                  className={Utils.getRecommendationColor(
                    props.stckLst[index].recommendation
                  )}
                >
                  {props.stckLst[index].recommendation}{" "}
                </Label>
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Stock SnapShot
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.Cell key={uuid()}>
                <Segment textAlign={"center"}>
                  <StockRadarChart
                    scoreData={props.stckLst[index]}
                    width={120}
                    height={100}
                    backgroundColor="#F9F8F6"
                    labelSize={"short"}
                  ></StockRadarChart>
                </Segment>
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Company Size
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.Cell key={uuid()}>
                {props.stckLst[index].companySize}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Market Cap
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.Cell key={uuid()}>
                {Utils.format_currency_short(props.stckLst[index].marketCap)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance 1 Day
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfDay,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance 1 Week
            </Table.Cell>

            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfWeek,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance 1 Month
            </Table.Cell>

            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfMonth,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance 1 Year
            </Table.Cell>

            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfYear,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance YTD
            </Table.Cell>

            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfYTD,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance 5Y
            </Table.Cell>

            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfYTD5,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Performance 10Y
            </Table.Cell>

            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].perfYTD10,
                Utils.format_ratio,
                0,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Current Price
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) => (
              <Table.Cell key={uuid()}>
                {Utils.format_currency_usd(props.stckLst[index].price)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Fair Value
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].intrinsicValue,
                Utils.format_currency_usd,
                props.stckLst[index].price,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Above/Below Fair Value
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].discIntrinsic,
                Utils.format_ratio,
                0,
                0
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Dividend Yield
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].dividendYield,
                Utils.format_ratio,
                4,
                0
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              PE
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(props.stckLst[index].pe, Utils.format_none, 15, 0)
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              PEG
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].pegRatio,
                Utils.format_number,
                1.5,
                0
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Sales Growth (5y)
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].salesGrowth,
                Utils.format_number,
                10,
                1,
                "%"
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Net Income Growth
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].netIncomeGrowth,
                Utils.format_number,
                10,
                1,
                "%"
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Cashflow Growth
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].cashflowGrowth,
                Utils.format_ratio,
                10,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Past EPS (5Y)
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].ePSpast5Y,
                Utils.format_ratio,
                10,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Future EPS (5Y)
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].ePSnext5Y,
                Utils.format_ratio,
                10,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              ROE
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].roe,
                Utils.format_none,
                12,
                1,
                "%"
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Gross Margin
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].grossMargin,
                Utils.format_ratio,
                50,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Profit Margin
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].profitMargin,
                Utils.format_ratio,
                25,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Current Ratio
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].currentRatio,
                Utils.format_none,
                1,
                1
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Debt Servicing Ratio
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].debtServicingRatio,
                Utils.format_ratio,
                30,
                0
              )
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell
              style={{
                backgroundColor: "#A9D0F5",
              }}
            >
              Debt/EQ
            </Table.Cell>
            {Object.keys(props.stckLst).map((index) =>
              Utils.TableCell(
                props.stckLst[index].debtEq,
                Utils.format_none,
                1,
                0
              )
            )}
          </Table.Row>
        </Table.Body>
      </Table>
      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.symbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="ui blue button"
            onClick={() => setstateValues({ ...stateValues, open: false })}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
