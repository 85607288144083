import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Menu, Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import CompareResults from "../main/CompareResults";
import { UserContext } from "../main/MainHome.js";
import Constants from "../util/Constants";
import LoadingSpinner from "../util/LoadingSpinner";
import NoData from "../util/NoData";
import CompanyHeader from "./CompanyHeader";
import CompanyProfile from "./CompanyProfile";
import CompleteReport from "./CompleteReport";
import ETFProfile from "./ETFProfile";
import FACheckList from "./FACheckList";
import Financials from "./Financials";
import IntrinsicValue from "./IntrinsicValue";
import IntrsicAccVal from "./IntrsicAccVal";
import StockChart from "./StockChart.js";
import ScreenerContainer from "../main/ScreenerContainer";

export default function CompanyPage(props) {
  const [stateValues, setstateValues] = useState({
    activeItem: "profile",
    stockValues: "",
    nodata: true,
    etf: false,
    compareResults: "",
    isFavorite: false,
    loading: true,
  });

  const userEmail = useContext(UserContext);

  //console.log("CompanyPage is called");

  useEffect(() => {
    //console.log("Companypage useeffect..................", props.symbol);

    setstateValues({ ...stateValues, loading: true });

    var urlVal =
      Constants.SERVICE_URL +
      "?userEmail=" +
      userEmail +
      "&symbol=" +
      props.symbol;

    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        //console.log("Companypage useeffect..................", response.data);

        setstateValues({
          stockValues: response.data.calStkVal,
          activeItem: response.data.calStkVal
            ? response.data.calStkVal.compProfile.etf === true
              ? "etf"
              : "profile"
            : "profile",
          nodata: response.data.calStkVal.notEnoughData,
          etf: response.data.calStkVal.compProfile.etf,
          isFavorite: response.data.isFavorite,
          compareResults: response.data.compareResults,
          portfolio: response.data.portfolio,
          loading: false,
        });
      })
      .catch(function (error) {
        console.log("Home: Company Page response Error:", error);
      });
  }, [props.symbol]);

  //console.log("stateValues:", stateValues);

  let seg = "";
  // Find the height
  let segmentHeight = "150vh";
  if (stateValues.activeItem === "complete") {
    segmentHeight = "850vh";
  } else if (stateValues.activeItem === "etf") {
    segmentHeight = "300vh";
  }

  //create profile page. dummy
  if (stateValues.activeItem === "profile") {
    seg = (
      <CompanyProfile
        userEmail={userEmail}
        stockValues={stateValues.stockValues}
      />
    );
  } else if (stateValues.activeItem === "chart") {
    seg = (
      <Segment>
        <StockChart symbol={stateValues.stockValues.compProfile.symbol} />;
      </Segment>
    );
  } else if (stateValues.activeItem === "intrinsic") {
    let content = <NoData />;
    let intrinsicVal = "";
    if (!stateValues.nodata) {
      intrinsicVal = <IntrinsicValue stockValues={stateValues.stockValues} />;
      content = <IntrsicAccVal stockValues={stateValues.stockValues} />;
    }
    seg = (
      <Segment>
        {intrinsicVal}
        {content}
      </Segment>
    );
  } else if (stateValues.activeItem === "financials") {
    let content = <NoData />;
    if (!stateValues.nodata) {
      content = <Financials stockValues={stateValues.stockValues} />;
    }
    seg = <Segment>{content}</Segment>;
  } else if (stateValues.activeItem === "fundamentals") {
    let content = <NoData />;
    if (!stateValues.nodata) {
      content = <FACheckList stockValues={stateValues.stockValues} />;
    }
    seg = <>{content}</>;
  } else if (stateValues.activeItem === "complete") {
    let content = <NoData />;
    if (!stateValues.nodata) {
      content = (
        <CompleteReport
          stockValues={stateValues.stockValues}
          compareResults={stateValues.compareResults}
          userEmail={userEmail}
        />
      );
    }
    seg = <Segment>{content}</Segment>;
  } else if (stateValues.activeItem === "peers") {
    if (!stateValues.nodata) {
      seg = (
        <Segment
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompareResults
            stckLst={stateValues.compareResults}
            userEmail={userEmail}
          />
        </Segment>
      );
    } else {
      seg = (
        <Segment>
          <NoData />;
        </Segment>
      );
    }
  } else if (stateValues.activeItem === "etf") {
    seg = (
      <Segment>
        <ETFProfile
          userEmail={userEmail}
          stockValues={stateValues.stockValues}
        />
      </Segment>
    );
  } else {
    seg = (
      <Segment>
        <ScreenerContainer
          userEmail={props.userEmail}
          portfolio={stateValues.portfolio}
        />
      </Segment>
    );
  }

  // ** Function to tab selection
  function handleItemClick(e, { name }) {
    setstateValues({ ...stateValues, activeItem: name });
  }

  return stateValues.loading ? (
    <LoadingSpinner />
  ) : stateValues.stockValues ? (
    <div>
      {stateValues.etf === false ? (
        <Menu
          stackable
          style={{ backgroundColor: "#2B3856" }}
          inverted
          pointing
        >
          <Menu.Item
            name="profile"
            content="Company Details"
            active={stateValues.activeItem === "profile"}
            onClick={handleItemClick}
          />{" "}
          <Menu.Item
            name="intrinsic"
            content="Intrinsic Value"
            active={stateValues.activeItem === "intrinsic"}
            onClick={handleItemClick}
          />{" "}
          <Menu.Item
            name="financials"
            content="Financials"
            active={stateValues.activeItem === "financials"}
            onClick={handleItemClick}
          />{" "}
          <Menu.Item
            name="fundamentals"
            content="Fundamental Analysis"
            active={stateValues.activeItem === "fundamentals"}
            onClick={handleItemClick}
          />{" "}
          <Menu.Item
            name="complete"
            content="Complete Report"
            active={stateValues.activeItem === "complete"}
            onClick={handleItemClick}
          />{" "}
          <Menu.Item
            name="peers"
            content="Compare to Peers"
            active={stateValues.activeItem === "peers"}
            onClick={handleItemClick}
          />
        </Menu>
      ) : (
        <Menu style={{ backgroundColor: "#2B3856" }} inverted pointing>
          <Menu.Item
            name="etf"
            content="ETF Details"
            active={stateValues.activeItem === "etf"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="holdings"
            content="Stock Holdings"
            active={stateValues.activeItem === "holdings"}
            onClick={handleItemClick}
          />{" "}
        </Menu>
      )}

      <CompanyHeader
        stockValues={stateValues.stockValues}
        isFavorite={stateValues.isFavorite}
      />

      {seg}
    </div>
  ) : null;
}
