import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Header, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import Utils from "../util/Utils";
import uuid from "react-uuid";

export default function IPOCalendar(props) {
  function getURL(symbol) {
    return "https://finviz.com/quote.ashx?t=" + symbol;
  }

  const [ipos, setIpos] = useState([]);

  useEffect(() => {
    console.log("In IPO Calendar");

    var prior = new Date().setDate(new Date().getDate() - 10);
    var from = new Date(prior).toISOString().slice(0, 10);

    var future = new Date().setDate(new Date().getDate() + 10);
    var to = new Date(future).toISOString().slice(0, 10);

    let ipoURL = Constants.IPO_URL + "&from=" + from + "&to=" + to;

    axios({
      method: "get",
      responseType: "json",
      url: ipoURL,
    })
      .then((response) => {
        setIpos(response.data.ipoCalendar);
      })
      .catch(function (error) {
        //console.log("Home:Portfolio Performance response Error:", error);
      });
  }, []);

  return (
    <>
      <div
        style={{
          marginLeft: "30%",
          marginRight: "30%",
        }}
      >
        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Header color="blue" textAlign="center" as="h2">
                Recent and upcoming IPOs
              </Header>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>

      <Card fluid raised>
        <Card.Content>
          <Card.Description>
            <Table celled compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Date
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Symbol
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Exchange
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Company Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Number of Shares
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Initial Price
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Total Money Raising
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      backgroundColor: "#A9D0F5",
                    }}
                  >
                    Status
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(ipos).map((index) => (
                  <Table.Row key={uuid()}>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      {ipos[index].date}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      {ipos[index].status === "priced" ? (
                        <a
                          title="finviz"
                          href={getURL(ipos[index].symbol)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {ipos[index].symbol}
                        </a>
                      ) : (
                        ipos[index].symbol
                      )}
                    </Table.Cell>

                    <Table.Cell>{ipos[index].exchange}</Table.Cell>
                    <Table.Cell>{ipos[index].name}</Table.Cell>
                    <Table.Cell>
                      {Utils.format_number(ipos[index].numberOfShares)}
                    </Table.Cell>
                    <Table.Cell>{ipos[index].price}</Table.Cell>
                    <Table.Cell>
                      {Utils.format_currency_long(ipos[index].totalSharesValue)}
                    </Table.Cell>

                    <Table.Cell>{ipos[index].status}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
}
