import React, { useState } from "react";
import { Button, Card, Header, Image, Modal, Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockRadarChart from "./StockRadarChart";
import CompanyPage from "../company/CompanyPage";

export default function SnapShots(props) {
  //console.log("Favorites", props.favoritesData.stockReports);
  //alert("in snapshots");
  const sr = props.stockReports
    ? !props.displayAll
      ? props.stockReports.slice(0, 50)
      : props.stockReports
    : null;

  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
    loading: true,
  });

  const showModal = (symbol) => {
    setstateValues({ ...stateValues, modalSymbol: symbol, open: true });
  };

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  //console.log("Favorites****", sr ? sr.size : null);

  return (
    <>
      <div
        id="favorites"
        style={{
          minHeight: "74vh",
          marginBottom: "2%",
        }}
      >
        {!props.displayAll ? (
          <Header as="h4">Note: Only top 50 are shown below</Header>
        ) : null}

        <Card.Group centered itemsPerRow={6}>
          {sr &&
            sr.map((favorite) => (
              <Card
                key={favorite.symbol}
                raised
                fluid
                style={{
                  backgroundColor: "#203B5F",
                }}
                onClick={() => showModal(favorite.symbol)}
              >
                <Card.Content>
                  <Image
                    floated="left"
                    size="mini"
                    src={favorite.image}
                    rounded
                  />
                  <Card.Header
                    style={{
                      textAlign: "right",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {favorite.symbol}
                  </Card.Header>
                  <Card.Meta
                    style={{
                      textAlign: "right",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    ${favorite.price}
                  </Card.Meta>
                </Card.Content>
                <Card.Description>
                  <Segment>
                    <StockRadarChart
                      scoreData={favorite}
                      width={120}
                      height={100}
                      labelSize={"short"}
                    ></StockRadarChart>
                  </Segment>
                </Card.Description>
              </Card>
            ))}
        </Card.Group>
      </div>
      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.modalSymbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="ui blue button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
