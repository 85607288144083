import React, { Component } from "react";
import { Card, Divider, Grid, Header, Icon, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class RoeCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h3">
          <Icon name="sort amount up" />
          Does it have High & Consistent ROE ?
        </Header>
        <Header as="h3">(12-15% is ideal)</Header>
      </Divider>
      <Grid stackable centered>
        <Grid.Column width={8}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>Return on Equity (ROE)</Card.Header>
              <Card.Description>
                <Table definition compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Must be &gt; 12</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.roe,
                        Utils.format_none,
                        12,
                        1,
                        "%"
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              It measure of efficiency of the company at generating profit with
              respect to its net worth
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </>
  );
}
