import { Component, default as React } from "react";
import "semantic-ui-css/semantic.min.css";
import { Divider, Grid, Segment } from "semantic-ui-react";
import "../css/home.css";
import "../css/index.css";

export default class Footer extends Component {
  // ** Fuction for button on click

  render() {
    return (
      <>
        <Segment
          style={{
            backgroundColor: "#2C3539",
          }}
        >
          <Grid>
            <Grid.Row columns={3} style={{ maxHeight: "1vh" }}>
              <Grid.Column textAlign="right">
                <p>About Us</p>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <a
                  title="Terms and Conditions"
                  href={"./TC.html"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <p>Terms and Conditions </p>
                </a>
              </Grid.Column>
              <Grid.Column textAlign="left">
                <p>Privacy Policy </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} style={{ minHeight: "15vh" }}>
              <Grid.Column>
                <Divider></Divider>
                <div
                  textAlign="left"
                  style={{
                    margin: "5px",
                  }}
                >
                  SimplyStock.net strives to make complex stock analysis more
                  accessible and user-friendly. It is important to note that
                  SimplyStock.net does not function as a stockbroker, dealer, or
                  investment advisor. The information provided on the website
                  should never be interpreted as a recommendation to buy or sell
                  a particular stock. While SimplyStock.net makes every effort
                  to ensure the accuracy and completeness of the information
                  presented, they cannot guarantee it. Users are solely
                  responsible for their investment decisions and SimplyStock.net
                  will not be held liable for any financial losses incurred as a
                  result of using the website's content. For further details,
                  please refer to SimplyStock.net's Terms and Conditions and
                  Privacy Policy.
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    );
  }
}
