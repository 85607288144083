import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "./css/index.css";
import MainHome from "./main/MainHome";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <GoogleOAuthProvider clientId="17726506230-ilk8h2deqvko7emhb7dsiobe4g2guvjn.apps.googleusercontent.com">
    <MainHome />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
