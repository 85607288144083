import axios from "axios";
import { Component, default as React } from "react";
import "semantic-ui-css/semantic.min.css";
import { Button, Form, Input, Message, TextArea } from "semantic-ui-react";
import "../css/home.css";
import "../css/index.css";

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

export default class SubmitFeature extends Component {
  // ** Fuction for button on click

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      requestDetails: "",
      errors: [],
      successMessage: "",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    let message = "";

    this.setState({ successMessage: "" });

    const { name, value } = e.target;
    this.setState({ [name]: value });

    let errorMessages = [];
    let errorMessage = "";

    errorMessage =
      this.state.name.length < 5 ? "Name must be 5 characters long!" : "";
    if (errorMessage) {
      errorMessages.push(errorMessage);
    }
    errorMessage = validEmailRegex.test(this.state.email)
      ? ""
      : "Email is not valid!";
    if (errorMessage) {
      errorMessages.push(errorMessage);
    }
    errorMessage =
      this.state.requestDetails.length < 25
        ? "Request Details must be minimum 25 characters long!"
        : "";
    if (errorMessage) {
      errorMessages.push(errorMessage);
    }
    this.setState({ errors: errorMessages });

    if (validateForm(errorMessages)) {
      console.info("Valid Form");
      axios
        .post(this.props.contactURL, this.state)
        .then((response) => {
          if (response.status === 200) {
            message = "Your request has been submitted! Thank you.";
            this.setState({ successMessage: message });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    //console.log("In Feature Request");
    let errorVar = this.state.errors;
    return (
      <div
        style={{
          width: "auto",
          marginTop: "6%",
          marginBottom: "2%",
        }}
      >
        <div
          style={{
            marginLeft: "25%",
            width: "50%",
          }}
        >
          <Message
            attached
            header="Welcome to our site!"
            content={this.props.header}
          />

          {this.state.errors.length > 0 && (
            <Message
              error
              header="There was some errors with your submission"
              list={errorVar}
            />
          )}
          {(errorVar = "")}
          <Form
            className="attached fluid segment"
            onSubmit={this.handleFormSubmit}
          >
            <Form.Field required>
              <label>Name</label>
              <Input name="name" onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field required>
              <label>Email</label>
              <Input
                name="email"
                type="email"
                onChange={this.handleInputChange}
              />
            </Form.Field>

            <Form.Field required>
              <label>Request Details</label>
              <TextArea
                name="requestDetails"
                onChange={this.handleInputChange}
              />
            </Form.Field>

            <Button className="ui blue button" type="submit">
              Submit
            </Button>
            {this.state.successMessage.length > 0 && (
              <Message positive>
                <Message.Header> {this.state.successMessage}</Message.Header>
              </Message>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
