import React from "react";
import TradingViewWidget, { BarStyles } from "react-tradingview-widget";
import { Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import withWindowSize from "react-window-size";

const StockChart = (props) => {
  return (
    <Segment raised>
      <TradingViewWidget
        symbol={props.symbol}
        allow_symbol_change={false}
        hide_top_toolbar={true}
        range={"6m"}
        style={BarStyles.LINE}
        studies={[
          {
            id: "MASimple@tv-basicstudies",
            version: 60,
            inputs: {
              length: 50,
            },
          },
          {
            id: "MASimple@tv-basicstudies",
            version: 60,
            inputs: {
              length: 100,
            },
          },
          {
            id: "MASimple@tv-basicstudies",
            version: 60,
            inputs: {
              length: 200,
            },
          },
        ]}
        studies_overrides={[{ "moving average.length": 50 }]}
        width={props.windowWidth < 768 ? 420 : 630}
        height={props.windowHeight / 3}
      />
    </Segment>
  );
};
export default withWindowSize(StockChart);
