import React, { useState } from "react";
import { Card, Header, Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockChart from "../company/StockChart";
import { MiniChart } from "react-ts-tradingview-widgets";
import { CopyrightStyles } from "react-ts-tradingview-widgets";
import { Borders } from "react-vis";

export default function StockCharts(props) {
  //console.log("Favorites", props.favoritesData.stockReports);
  //alert("in snapshots");
  const sr = props.stockReports
    ? !props.displayAll
      ? props.stockReports.slice(0, 50)
      : props.stockReports
    : null;

  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
    loading: true,
  });

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  const styles = {
    parent: {
      fontSize: "1px",
      color: "white",
    },
    link: {
      textDecoration: "line-trough",
    },
    span: {
      color: "white",
    },
  };

  //console.log("Favorites****", sr ? sr.size : null);

  return (
    <div
      id="stockchartsdiv"
      style={{
        minHeight: "74vh",
        marginBottom: "2%",
      }}
    >
      {!props.displayAll ? (
        <Header as="h4">Note: Only top 50 are shown below</Header>
      ) : null}

      <Card.Group centered>
        {sr &&
          sr.map((favorite) => (
            <div style={{ border: "none" }}>
              <MiniChart
                dateRange="3M"
                symbol={favorite.symbol}
                height={200}
                trendLineColor="rgba(101, 101, 101, 1)"
                copyrightStyles={styles}
                largeChartUrl=""
              />
            </div>
          ))}
      </Card.Group>
    </div>
  );
}
