import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class Legend extends Component {
  render() {
    //console.log("In Legend");

    return (
      <Table unstackable collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell
              style={{
                color: "white",
                backgroundColor: "#495674",
              }}
              textAlign="center"
            >
              Legend
            </Table.Cell>
            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("green3"),
              }}
            >
              +3%
            </Table.Cell>
            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("green2"),
              }}
            >
              +2%
            </Table.Cell>
            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("green1"),
              }}
            >
              +1%
            </Table.Cell>

            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("none"),
              }}
            >
              +0%
            </Table.Cell>

            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("red1"),
              }}
            >
              -1%
            </Table.Cell>

            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("red2"),
              }}
            >
              -2%
            </Table.Cell>

            <Table.Cell
              style={{
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor("red3"),
              }}
            >
              -3%
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
