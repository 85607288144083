import React, { Component } from "react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import { Header, Table, Icon, Card } from "semantic-ui-react";

export default class Help extends Component {
  render() {
    //console.log("In Help");

    return (
      <>
        <div
          style={{
            marginLeft: "30%",
            marginRight: "30%",
          }}
        >
          <Card fluid raised>
            <Card.Content>
              <Card.Description>
                <Header color="blue" textAlign="center" as="h2">
                  <Icon name="info circle" />
                  Getting Started
                </Header>
              </Card.Description>
            </Card.Content>
          </Card>
        </div>

        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Table celled compact>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Access</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. This site currently works only with Google Sign In.
                      </p>
                      <p>
                        2. After you sign with Google, If your id is not found,
                        you will be directed to Access Request page. Please fill
                        in your details and submit your request.
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Requesting a feature</Header>{" "}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. If you would like to submit a feature request, please
                        navigate to Help -&gt; Submit Feature from the top menu
                        on the home page.
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Researching companies</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. You can type a company name or ticker name in the
                        Search Input and select the matching company from the
                        search results
                      </p>
                      <p>
                        2. If a company name has two words (e.g. Service Now,
                        try searching "Service" or "Now")
                      </p>
                      <p>
                        3. Once a company is selected from search results, the
                        details of the company are displayed on the main page
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Company Details screen</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. Company Details tab shows key information of the
                        company
                      </p>
                      <p>
                        2. Chart tab shows real time price chart of the company
                      </p>
                      <p>
                        3. Intrinsic Value (IV) tab shows the 4 different IV
                        calculation methods and details behind each calculation
                      </p>
                      <p>
                        4. Financials tab shows the company's financials
                        statements (Income Statement, Balance Sheet, Cashflow
                        statement) for the last 5 years .
                      </p>
                      <p>
                        5. Fundamental Analysis tab shows the color coded
                        fundamental analysis results
                      </p>
                      <p>
                        - Sales/Revenue Check - shows the historical sales,
                        growth percentages, trends and observations
                      </p>
                      <p>
                        - Earnings Check - shows the historical Earnings growth,
                        percentages, trends and analysts future growth estimate
                      </p>
                      <p>
                        - Cashflow Check - shows the historical cashflow growth,
                        percentages and trends
                      </p>
                      <p>
                        - Economic Moat - shows Gross Margin and Profit Margin
                        historical growth
                      </p>
                      <p>
                        - Debt Check - shows the current ratio, debt to equity
                        and debt servicing ratio details
                      </p>
                      <p>
                        - Fair Price Check - shows the Various Valuation details
                        (PEG, Intrinsic Value and Book Value)
                      </p>
                      <p>6. Technical Analysis (Coming Soon!)</p>
                      <p>
                        7. Complete Report tab shows all the above details in
                        one single page
                      </p>
                      <p>
                        8. Compare to Peers tab shows a comparison of the
                        currently selected company to other major peers in that
                        industry
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Compare Stocks</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. Compare stocks allows to select up to 4 stocks and
                        Compare them
                      </p>
                      <p>
                        2. once a ticker is selected from the dropdown, it can
                        be removed by selecting the x from the selected list
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Screener</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. The Screener allows the see the complete report of
                        all the stocks and behind the scenes scoring
                      </p>
                      <p>
                        2. You can click on the header columns to sort in
                        ascending and descending order
                      </p>
                      <p>
                        3. You can select a column filter icon (next to the
                        column label) and enter your search criteria to and
                        filter results
                      </p>
                      <p>
                        4. To remove the search criteria, go back to the column
                        and click on the filter icon and remove the search
                        criteria
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3"> Ideas</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. There are several curated ideas readily available for
                        you to explore
                      </p>
                      <p>
                        2. Best of All - shows the best companies that met all
                        fundamental checks
                      </p>
                      <p>
                        3. Top 50 - shows list of the top 50 companies stack
                        ranked by their fundamental scores
                      </p>
                      <p>
                        4. Large Cap Growth - shows a list of large cap
                        companies that passed fundamental checks
                      </p>
                      <p>
                        5. Companies recovered bottom - shows a list of
                        companies that recovered from the last market crash
                      </p>
                      <p>
                        6. Great Small & Mid companies - shows a list of small
                        cap and mid cap companies that passed fundamental checks
                      </p>
                      <p>
                        7. Gems under $20 - shows companies regardless of their
                        size and passed fundamental checks and their current
                        price is below $20
                      </p>
                      <p>
                        8. Good Dividend Stocks - shows companies that are
                        paying good dividend and passed fundamental checks
                      </p>
                      <p>
                        9. Speculative Growth Stocks - shows companies that have
                        consistent sales growth but not profitable yet
                      </p>
                      <p>
                        10. Good companies that have room for growth - shows
                        companies that hit their bottom and passed fundamental
                        checks and have changed to recover back (coming soon!)
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Fund Gurus</Header>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: "#FBF8EF",
                      }}
                    >
                      <p>
                        1. Founder's Watchlist - shows companies that are
                        currently watched by the founders of SimplyStock
                      </p>
                      <p>
                        2. Each Menu item - shows companies that are currently
                        owned by top hedge fund managers such Bill Ackman, Guy
                        Spier etc.
                      </p>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </>
    );
  }
}
