import React, { Component } from "react";
import { Card, Divider, Grid, Header, Icon, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class DebtCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h3">
          <Icon name="balance" />
          Is the company Finacially healthy ?
        </Header>
      </Divider>

      <Grid centered stackable>
        <Grid.Column width={3}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>Current Ratio</Card.Header>
              <Card.Description>
                <Table definition compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Must be &gt; 1</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.currentRatio,
                        Utils.format_none,
                        1,
                        1
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              It measures the capacity of the company to pay off its immediate
              liabilities with the immediately liquifiable assets
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={3}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>Debt Servicing Ratio</Card.Header>
              <Card.Description>
                <Table definition compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Must be &lt; 30%</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.debtServicingRatio,
                        Utils.format_none,
                        30,
                        0
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              Debt servicing ratio is a measurement of the cash flow spent on
              current debt obligations.
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={3}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>Debt Equity Ratio</Card.Header>
              <Card.Description>
                <Table definition compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Must be &lt; 1</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.debtEq,
                        Utils.format_none,
                        1,
                        0
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              Debt Equity ratio is a measurement a company’s total liabilities
              by its shareholder equity
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </>
  );
}
