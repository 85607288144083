import React, { Component } from "react";
import { Table, Header, Image, Popup } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import Utils from "../util/Utils";

import uuid from "react-uuid";

export default class SectorGainersLosers extends Component {
  // Gainers or Losers Row
  getGainersLosersGrid = (name, list, onClickFunction, positiveExpected) => {
    //console.log(name, ":", list);
    return (
      <Table.Cell>
        <Table compact="very" collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2} textAlign="center">
                {name}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {list ? (
              Object.keys(list).map((index) =>
                (positiveExpected && list[index].changesPercentage > 0) ||
                (!positiveExpected && list[index].changesPercentage < 0) ? (
                  <Table.Row key={uuid()}>
                    <Table.Cell
                      key={list[index].symbol}
                      positive={positiveExpected ? true : false}
                      negative={!positiveExpected ? true : false}
                      textAlign="left"
                      verticalAlign="middle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => onClickFunction(list[index].symbol)}
                    >
                      <Popup
                        size="large"
                        inverted
                        trigger={
                          <p>
                            <Header as="h6" image>
                              <Image
                                style={{
                                  mixBlendMode: "multiply",
                                }}
                                src={
                                  Constants.IMAGE_URL +
                                  list[index].symbol +
                                  ".jpg"
                                }
                                rounded
                              />
                            </Header>
                            {list[index].symbol}
                          </p>
                        }
                      >
                        <Popup.Header>
                          {list[index].name}
                          <br />
                          {Utils.format_currency_usd(list[index].price)}{" "}
                        </Popup.Header>

                        <Popup.Content>
                          {Utils.format_currency_usd(list[index].change) + " "}(
                          {list[index].changesPercentage}%)
                        </Popup.Content>
                      </Popup>
                    </Table.Cell>
                    <Table.Cell
                      key={uuid()}
                      positive={positiveExpected ? true : false}
                      negative={!positiveExpected ? true : false}
                      textAlign="center"
                    >
                      {Utils.format_number(list[index].changesPercentage)}%
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row key={uuid()}>
                    <Table.Cell textAlign="center" colSpan={2}>
                      -
                    </Table.Cell>
                  </Table.Row>
                )
              )
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={2}>
                  -
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Cell>
    );
  };
  render() {
    //console.log("In Gainers and Losers");

    return (
      <>
        <Table compact={"very"} collapsing>
          <Table.Body>
            <Table.Row>
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Technology Gainers",
                    this.props.gainersLosers.sectorGainers["Technology"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Basic Materials Gainers",
                    this.props.gainersLosers.sectorGainers["Basic Materials"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Comm Services Gainers",
                    this.props.gainersLosers.sectorGainers[
                      "Communication Services"
                    ],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Consumer Cyclical Gainers",
                    this.props.gainersLosers.sectorGainers["Consumer Cyclical"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Consumer Defensive Gainers",
                    this.props.gainersLosers.sectorGainers[
                      "Consumer Defensive"
                    ],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Energy Gainers",
                    this.props.gainersLosers.sectorGainers["Energy"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
            </Table.Row>
            <Table.Row>
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Financial Gainers",
                    this.props.gainersLosers.sectorGainers["Financial"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Healthcare Gainers",
                    this.props.gainersLosers.sectorGainers["Healthcare"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Industrials Gainers",
                    this.props.gainersLosers.sectorGainers["Industrials"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Real Estate Gainers",
                    this.props.gainersLosers.sectorGainers["Real Estate"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Utilities Gainers",
                    this.props.gainersLosers.sectorGainers["Utilities"],
                    this.props.onClickSymbolFunction,
                    true
                  )
                : null}
            </Table.Row>

            <Table.Row>
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Technology Losers",
                    this.props.gainersLosers.sectorLosers["Technology"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Basic Materials Losers",
                    this.props.gainersLosers.sectorLosers["Basic Materials"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Comm Services Losers",
                    this.props.gainersLosers.sectorLosers[
                      "Communication Services"
                    ],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Consumer Cyclical Losers",
                    this.props.gainersLosers.sectorLosers["Consumer Cyclical"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Consumer Defensive Losers",
                    this.props.gainersLosers.sectorLosers["Consumer Defensive"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Energy Losers",
                    this.props.gainersLosers.sectorLosers["Energy"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
            </Table.Row>
            <Table.Row>
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Financial Losers",
                    this.props.gainersLosers.sectorLosers["Financial"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}

              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Healthcare Losers",
                    this.props.gainersLosers.sectorLosers["Healthcare"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Industrials Losers",
                    this.props.gainersLosers.sectorLosers["Industrials"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Real Estate Losers",
                    this.props.gainersLosers.sectorLosers["Real Estate"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
              {this.props.gainersLosers
                ? this.getGainersLosersGrid(
                    "Utilities Losers",
                    this.props.gainersLosers.sectorLosers["Utilities"],
                    this.props.onClickSymbolFunction,
                    false
                  )
                : null}
            </Table.Row>
          </Table.Body>
        </Table>
      </>
    );
  }
}
