import React, { useState, useEffect } from "react";
import { Table, Header } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants, { API_KEYS } from "../util/Constants";
import axios from "axios";

export default function StockGrade(props) {
  const gradeValues = [
    { grade: "Sell", value: 1 },
    { grade: "Strong Sell", value: 1 },
    { grade: "Underperform", value: 2 },
    { grade: "Moderate Sell", value: 2 },
    { grade: "Weak Hold", value: 2 },
    { grade: "Underweight", value: 2 },
    { grade: "Reduce", value: 2 },
    { grade: "Hold", value: 3 },
    { grade: "Neutral", value: 3 },
    { grade: "In-line", value: 3 },
    { grade: "Peer Perform", value: 3 },
    { grade: "Equal-Weight", value: 3 },
    { grade: "Sector Weight", value: 3 },
    { grade: "Outperform", value: 4 },
    { grade: "Market Perform", value: 4 },
    { grade: "Moderate Buy", value: 4 },
    { grade: "Accumulate", value: 4 },
    { grade: "Overweight", value: 4 },
    { grade: "Add", value: 4 },
    { grade: "Buy", value: 5 },
    { grade: "Strong Buy", value: 5 },
  ];

  const [grades, setGrades] = useState([]);

  useEffect(() => {
    //console.log("StockGrade useEffect ", props);

    let urlVal =
      Constants.GRADE_URL + props.symbol + "?limit=10&" + API_KEYS.FMP_API_KEY;

    //console.log("StockGrade useEffect ", urlVal);

    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        //console.log("StockGrade response.data.gradesData ", response.data);

        setGrades(response.data);
      })
      .catch(function (error) {
        console.log("StockGrade Fetch Error:", error);
      });
  }, [props.symbol]);

  const getGradeRow = (grade) => {
    //console.log("grade", grade);

    let prevGradeVal = gradeValues.find(function (element) {
      return element.grade === grade.previousGrade;
    }, grade.previousGrade);
    let newGradeVal = gradeValues.find(function (element) {
      return element.grade === grade.newGrade;
    }, grade.newGrade);

    return (
      <Table.Row key={grade.date + grade.gradingCompany}>
        <Table.Cell
          negative={
            prevGradeVal && newGradeVal
              ? prevGradeVal.value > newGradeVal.value
              : false
          }
          positive={
            prevGradeVal && newGradeVal
              ? prevGradeVal.value < newGradeVal.value
              : false
          }
        >
          {grade.date}
        </Table.Cell>
        <Table.Cell
          negative={
            prevGradeVal && newGradeVal
              ? prevGradeVal.value > newGradeVal.value
              : false
          }
          positive={
            prevGradeVal && newGradeVal
              ? prevGradeVal.value < newGradeVal.value
              : false
          }
        >
          {grade.gradingCompany.substring(0, 15)}
        </Table.Cell>

        <Table.Cell
          negative={
            prevGradeVal && newGradeVal
              ? prevGradeVal.value > newGradeVal.value
              : false
          }
          positive={
            prevGradeVal && newGradeVal
              ? prevGradeVal.value < newGradeVal.value
              : false
          }
        >
          {grade.previousGrade} -&gt; {grade.newGrade}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <Table compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={3}>
              <Header as="h3" textAlign="left">
                Stock Upgrade/Downgrade
              </Header>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {grades &&
            Object.keys(grades).map((index) => getGradeRow(grades[index]))}
        </Table.Body>
      </Table>
    </>
  );
}
