import React from "react";
import { Table, Header, Image, Popup } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import Utils from "../util/Utils";

import uuid from "react-uuid";

export default function GainersLosers(props) {
  // Gainers or Losers Row
  const getGainersLosersGrid = (
    name,
    list,
    onClickFunction,
    positiveExpected
  ) => {
    //console.log("GainersLosers Rendering:", name);
    return (
      <Table.Cell>
        <Table compact="very" collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2} textAlign="center">
                {name}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {list ? (
              Object.keys(list).map((index) =>
                (list[index] != null &&
                  positiveExpected &&
                  list[index].changesPercentage > 0) ||
                (!positiveExpected &&
                  list[index] != null &&
                  list[index].changesPercentage < 0) ? (
                  <Table.Row key={uuid()}>
                    <Table.Cell
                      key={list[index].symbol}
                      positive={positiveExpected ? true : false}
                      negative={!positiveExpected ? true : false}
                      textAlign="left"
                      verticalAlign="middle"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => onClickFunction(list[index].symbol)}
                    >
                      <Popup
                        size="large"
                        inverted
                        trigger={
                          <p>
                            <Header as="h6" image>
                              <Image
                                style={{
                                  mixBlendMode: "multiply",
                                }}
                                src={
                                  Constants.IMAGE_URL +
                                  list[index].symbol +
                                  ".jpg"
                                }
                                rounded
                              />
                            </Header>
                            {list[index].symbol}
                          </p>
                        }
                      >
                        <Popup.Header>
                          {list[index].name}
                          <br />
                          {Utils.format_currency_usd(list[index].price)}{" "}
                        </Popup.Header>

                        <Popup.Content>
                          {Utils.format_currency_usd(list[index].change) + " "}(
                          {list[index].changesPercentage}%)
                        </Popup.Content>
                      </Popup>
                    </Table.Cell>
                    <Table.Cell
                      key={uuid()}
                      positive={positiveExpected ? true : false}
                      negative={!positiveExpected ? true : false}
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      {Utils.format_number(list[index].changesPercentage)}%
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row key={uuid()}>
                    <Table.Cell textAlign="center" colSpan={2}>
                      -
                    </Table.Cell>
                  </Table.Row>
                )
              )
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={2}>
                  -
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Cell>
    );
  };

  return (
    <Table compact={"very"} collapsing>
      <Table.Body>
        <Table.Row>
          {props.gainersLosers
            ? getGainersLosersGrid(
                "Top 5 Gainers",
                props.gainersLosers.topGainers,
                props.onClickSymbolFunction,
                true
              )
            : null}

          {props.gainersLosers
            ? getGainersLosersGrid(
                "Dow Top 5 Gainers",
                props.gainersLosers.dowGainers,
                props.onClickSymbolFunction,
                true
              )
            : null}

          {props.gainersLosers
            ? getGainersLosersGrid(
                "S & P Top 5 Gainers",
                props.gainersLosers.spGainers,
                props.onClickSymbolFunction,
                true
              )
            : null}
          {props.gainersLosers
            ? getGainersLosersGrid(
                "NASDAQ Top 5 Gainers",
                props.gainersLosers.nasdaqGainers,
                props.onClickSymbolFunction,
                true
              )
            : null}
          {props.gainersLosers
            ? getGainersLosersGrid(
                "Russell Top 5 Gainers",
                props.gainersLosers.russellGainers,
                props.onClickSymbolFunction,
                true
              )
            : null}
        </Table.Row>
        <Table.Row>
          {props.gainersLosers
            ? getGainersLosersGrid(
                "Top 5 Losers",
                props.gainersLosers.topLosers,
                props.onClickSymbolFunction,
                false
              )
            : null}
          {props.gainersLosers
            ? getGainersLosersGrid(
                "Dow Top 5 Losers",
                props.gainersLosers.dowLosers,
                props.onClickSymbolFunction,
                false
              )
            : null}

          {props.gainersLosers
            ? getGainersLosersGrid(
                "S & P Top 5 Losers",
                props.gainersLosers.spLosers,
                props.onClickSymbolFunction,
                false
              )
            : null}
          {props.gainersLosers
            ? getGainersLosersGrid(
                "NASDAQ Top 5 Losers",
                props.gainersLosers.nasdaqLosers,
                props.onClickSymbolFunction,
                false
              )
            : null}
          {props.gainersLosers
            ? getGainersLosersGrid(
                "Russell Top 5 Losers",
                props.gainersLosers.russellLosers,
                props.onClickSymbolFunction,
                false
              )
            : null}
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
