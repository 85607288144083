import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import SectorUtil from "./SectorUtil";
import { Header } from "semantic-ui-react";
export default class SectorMap extends Component {
  render() {
    //console.log("In Sector Map");

    return (
      <div>
        <Header>
          <h2>{this.props.title}</h2>
        </Header>

        <div
          style={{
            marginTop: "1%",
          }}
        >
          <Grid stackable>
            {this.props.sectorMap && this.props.sectorMap["Technology"]
              ? SectorUtil.getSectorRow(
                  "Technology",
                  this.props.sectorMap["Technology"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Basic Materials"]
              ? SectorUtil.getSectorRow(
                  "Basic Materials",
                  this.props.sectorMap["Basic Materials"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap &&
            this.props.sectorMap["Communication Services"]
              ? SectorUtil.getSectorRow(
                  "Communication Services",
                  this.props.sectorMap["Communication Services"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Consumer Cyclical"]
              ? SectorUtil.getSectorRow(
                  "Consumer Cyclical",
                  this.props.sectorMap["Consumer Cyclical"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Consumer Defensive"]
              ? SectorUtil.getSectorRow(
                  "Consumer Defensive",
                  this.props.sectorMap["Consumer Defensive"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Energy"]
              ? SectorUtil.getSectorRow(
                  "Energy",
                  this.props.sectorMap["Energy"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Financial"]
              ? SectorUtil.getSectorRow(
                  "Financial",
                  this.props.sectorMap["Financial"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Healthcare"]
              ? SectorUtil.getSectorRow(
                  "Healthcare",
                  this.props.sectorMap["Healthcare"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Industrials"]
              ? SectorUtil.getSectorRow(
                  "Industrials",
                  this.props.sectorMap["Industrials"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Real Estate"]
              ? SectorUtil.getSectorRow(
                  "Real Estate",
                  this.props.sectorMap["Real Estate"],
                  this.props.onClickSymbolFunction
                )
              : null}
            {this.props.sectorMap && this.props.sectorMap["Utilities"]
              ? SectorUtil.getSectorRow(
                  "Utilities",
                  this.props.sectorMap["Utilities"],
                  this.props.onClickSymbolFunction
                )
              : null}
          </Grid>
        </div>
      </div>
    );
  }
}
