import axios from "axios";
import React, { Component } from "react";
import { Icon, Message } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";

export default class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexList: [],
    };
  }

  refreshPage = async () => {
    let indexURL = Constants.INDEX_URL;
    let gainersLosersURL = Constants.LEADERS_URL;

    // Make first two requests
    const [indexResponse, gainersLosersResponse] = await Promise.all([
      axios.get(indexURL),
      axios.get(gainersLosersURL),
    ]);

    let fdata = indexResponse.data.filter(
      (dataItem) =>
        dataItem.name === "Dow Jones Industrial Average" ||
        dataItem.name === "NASDAQ Composite" ||
        dataItem.name === "S&P 500" ||
        dataItem.name === "Russell 2000" ||
        dataItem.name === "CBOE VIX VOLATILITY INDEX"
    );
    /*console.log(
      "gainersLosersResponse.data.gainersLosers",
      gainersLosersResponse.data.gainersLosers
    );*/
    // Update state once with all 3 responses
    this.setState({
      indexList: fdata,
      gainersLosers: gainersLosersResponse.data.gainersLosers,
    });
    //console.log("dowMap:", gainersLosersResponse.data.gainersLosers.dowMap);
  };

  render() {
    return (
      <div
        id="container"
        style={{
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "1%",
            textAlign: "center",
          }}
        >
          <Message positive compact size="massive">
            <Icon name="bullhorn"></Icon>
            Hello {this.props.userName} Welcome to SimplyStock.Net!
          </Message>
          <br />
          <Message positive compact size="huge">
            We are glad you are here. SimplyStock.Net is dedicated to make the
            stock analysis easy, fast and simple. <br />
            You will find many investment ideas and information on stock/ETFs
            traded in major US exchanges in our website.
            <br />
            <br />
            Please read our{" "}
            <a
              title="Terms &amp; Conditions"
              href={"./TC.html"}
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </a>{" "}
            before exploring the website.
            <br />
            <br />
            Please use the "Submit Feature" link to submit any feedback or
            issues.
            <br />
          </Message>
          <br />
          <Message warning compact size="huge">
            <Icon name="warning circle"></Icon>
            Please note that this website is best viewed in Laptop/Desktop and
            Chrome/Microsoft Edge Browser. <br />
            (A mobile friendly version is coming soon...)
          </Message>
        </div>
      </div>
    );
  }
}
