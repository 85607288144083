import axios from "axios";
import { Component, default as React } from "react";
import ReactDOM from "react-dom";
import { GoogleLogin } from "@react-oauth/google";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Image,
  ListContent,
  Card,
  CardContent,
  List,
  ListItem,
} from "semantic-ui-react";
import "../css/home.css";
import "../css/index.css";
import Constants from "../util/Constants";
import Footer from "./Footer";
import Home from "./Home";

import SubmitFeature from "./SubmitFeature";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MainNews from "./MainNews";

export const UserContext = React.createContext();

export default class MainHome extends Component {
  // ** Fuction for button on click

  constructor(props) {
    super(props);

    this.state = {
      showMain: true,
      showAccessRequest: false,
      screenToRender: "",
    };
  }

  responseGoogle = (response) => {
    //console.log(response);
  };

  handleGoogleSignin = async (googleUser) => {
    const decodedCreds = jwtDecode(googleUser.credential);
    //validate the user.
    let userName = decodedCreds.name;
    let email = decodedCreds.email;

    var urlVal = Constants.VALIDATE_URL + email + "?name=" + userName;

    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        //console.log("Response:", response.data.user);
        var screen = "";
        if (response.data.user) {
          ReactDOM.render(
            <UserContext.Provider value={response.data.user.email}>
              <Home
                userName={response.data.user.name.split(" ")[0]}
                userEmail={response.data.user.email}
                newUser={response.data.user.newUser}
              />
            </UserContext.Provider>,
            document.getElementById("root")
          );
        } else {
          //console.log("Redirecting to Access Request Page");

          this.setState({ showAccessRequest: true, showMain: false });
          screen = (
            <SubmitFeature
              userName={userName}
              email={email}
              contactURL={Constants.ACCESS_REQUEST_URL}
              header="It appears you are not setup in the website yet.
          Don't feel bad. Fill out the form to request access."
            />
          );
        }
        this.setState({ screenToRender: screen });
      })
      .catch(function (error) {
        console.log("Error in fetching:", error);
      });
  };

  handleTempSignin = (bt_name) => {
    ReactDOM.render(
      <Home userName="SimplyStock" />,
      document.getElementById("root")
    );
  };

  render() {
    let tempSigninButton = "";
    let { showMain } = this.state;

    if (window.location.hostname === "localhost") {
      tempSigninButton = (
        <Button
          size="large"
          className="ui blue button"
          onClick={this.handleTempSignin.bind(this, "login")}
        >
          Temp Login
        </Button>
      );
    }

    return (
      <GoogleOAuthProvider clientId="17726506230-ilk8h2deqvko7emhb7dsiobe4g2guvjn.apps.googleusercontent.com">
        <div>
          <div
            style={{
              width: "100%",
              top: "0",
              position: "relative",
              zIndex: 1,
              backgroundColor: "#2C3539",
            }}
          >
            <List horizontal>
              <ListItem>
                <ListContent
                  verticalAlign="middle"
                  style={{
                    margin: "5%",
                  }}
                >
                  <Image
                    style={{ float: "left" }}
                    size="small"
                    verticalAlign="middle"
                    src={`${process.env.PUBLIC_URL}/images/simply.PNG`}
                  />
                </ListContent>
              </ListItem>
            </List>
            <List floated="right" horizontal>
              <ListItem>{tempSigninButton}</ListItem>
              <ListItem>
                <ListContent verticalAlign="middle">
                  <GoogleLogin
                    type="standard"
                    theme="outline"
                    size="medium"
                    text="signin_with"
                    onSuccess={this.handleGoogleSignin}
                    onFailure={this.responseGoogle}
                    useOneTap
                    cookiePolicy={"single_host_origin"}
                  />
                </ListContent>
              </ListItem>
            </List>
          </div>

          <Card fluid>
            <CardContent
              textAlign="center"
              style={{
                fontSize: "125%",
                fontFamily: "Verdana",
                fontWeight: "bold",
                color: "#1569C7",
              }}
            >
              Stock analysis done faster, easier and simpler!
            </CardContent>
            <CardContent
              extra
              textAlign="center"
              style={{
                fontFamily: "Verdana",
                color: "#1569C7",
              }}
            >
              Screen thousands of stocks, find curated ideas, compare stocks,
              and many more without hours of research.
            </CardContent>
          </Card>

          <MainNews />

          <div
            style={{
              bottom: 0,
              width: "100%",
              color: "white",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            <Footer></Footer>
          </div>
        </div>
      </GoogleOAuthProvider>
    );
  }
}
