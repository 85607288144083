import React from "react";
import { Grid } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockChart from "../company/MiniChart";

export default function IndexContainer(props) {
  return (
    <Grid stackable columns={1}>
      <Grid.Row>
        <div
          style={{
            fontSize: "125%",
            fontFamily: "Calibri",
            fontWeight: "bold",
            color: "#1569C7",
            marginLeft: "5%",
            marginBottom: "1%",
          }}
        >
          Live Indices
        </div>
        <Grid.Column fluid="true">
          <StockChart symbol={"DJI"} width={400} />
          <StockChart symbol={"FOREXCOM:SPX500"} />
          <StockChart symbol={"NASDAQ:IXIC"} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
