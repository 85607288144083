import React, { Component } from "react";
import { Card, Divider, Grid, Header, Table, Icon } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import ChartUtils from "../charts/ChartUtils";
import "../css/home.css";
import Utils from "../util/Utils";

export default class NetIncomeGrowthCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h3">
          <Icon size="large" name="chart line" />
          Is Net Income positive and growing consistently ? (10 to 15% growth is
          ideal)
        </Header>
      </Divider>
      <br />
      <Grid stackable centered>
        <Grid.Column width={3}>
          <div>
            {ChartUtils.renderGrowthCard(
              "Net Income",
              ChartUtils.getXyDataFromValues(
                this.props.stockValues.incomeStmtValues,
                "Net Income"
              ),
              Utils.format_number
            )}
          </div>
        </Grid.Column>
        <Grid.Column width="7">
          <Card fluid raised>
            <Card.Content>
              <Card.Header>Last 5 Years Values</Card.Header>
              <Card.Description>
                <Table
                  definition
                  collapsing
                  compact="very"
                  celled
                  style={{ margin: "0 auto" }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Year</Table.HeaderCell>
                      {Object.keys(this.props.stockValues.incomeStmtValues).map(
                        (index) => (
                          <Table.HeaderCell
                            key={
                              this.props.stockValues.incomeStmtValues[index][
                                "Net Income"
                              ].year
                            }
                          >
                            {this.props.stockValues.incomeStmtValues[index][
                              "Net Income"
                            ].year.substring(0, 4)}
                          </Table.HeaderCell>
                        )
                      )}
                      <Table.HeaderCell>5 Year Trend</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Net Income</Table.Cell>
                      {Object.keys(this.props.stockValues.incomeStmtValues).map(
                        (index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Net Income"
                            ].value,
                            Utils.format_currency_short,
                            10,
                            1
                          )
                      )}
                      {ChartUtils.renderTinyChart(
                        ChartUtils.getXyDataFromValues(
                          this.props.stockValues.incomeStmtValues,
                          "Net Income"
                        ),
                        "Value"
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>YoY Growth</Table.Cell>
                      {Object.keys(this.props.stockValues.incomeStmtValues).map(
                        (index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Net Income"
                            ].yoyGrowth,
                            Utils.format_percent,
                            0.1,
                            1
                          )
                      )}
                      {ChartUtils.renderTinyChart(
                        ChartUtils.getXyDataFromValues(
                          this.props.stockValues.incomeStmtValues,
                          "Net Income"
                        ),
                        "Growth"
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              Along with the sales/revenue, the profit (Net Income) of the
              company should also grow because it is the profit that matters at
              the end of the day. A company whose profit is stagnant or
              decreasing should be avoided. However, For early stage companies,
              it takes few years to become profitable.
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={3}>
          {ChartUtils.renderObservations(
            this.props.title,
            this.props.stockValues.netIncomeObservations
          )}
        </Grid.Column>
      </Grid>
    </>
  );
}
