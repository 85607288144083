import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import uuid from "react-uuid";

export default class Utils extends Component {
  state = {};

  static getCompanySize(marketCap) {
    let companySize = "";
    if (marketCap >= 200000000000) {
      companySize = "Mega";
    } else if (marketCap >= 10000000000) {
      companySize = "Large";
    } else if (marketCap >= 2000000000) {
      companySize = "Mid";
    } else if (marketCap >= 300000000) {
      companySize = "Small";
    } else if (marketCap >= 50000000) {
      companySize = "Micro";
    } else {
      companySize = "Nano";
    }
    return companySize;
  }

  static getFairPrice(value) {
    if (value === 0) {
      return "Not Available";
    }
    return Utils.format_currency_usd(value);
  }

  static ag_grid_cell_color_neg_val = (params) => {
    return params.value < 0 ? { color: "red" } : null;
  };

  static ag_formatNumber = (number) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  static ag_currencyFormatter = (params) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(params.value);
    //return "$" + this.ag_formatNumber(params.value);
  };

  static getDateTime = (params) => {
    return params.value.slice(0, 10) + " " + params.value.slice(12, 16);
    //return "$" + this.ag_formatNumber(params.value);
  };

  static ag_getDate = (params) => {
    return params.value.slice(0, 10);
    //return "$" + this.ag_formatNumber(params.value);
  };

  static ag_currencyCompactFormatter = (params) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
    }).format(params.value);
    //return "$" + this.ag_formatNumber(params.value);
  };
  static ag_PercentFormatter = (params) => {
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }).format(params.value);
  };

  static ag_RatioFormatter = (params) => {
    return (
      new Intl.NumberFormat("en-US", {
        notation: "compact",
      }).format(params.value) + "%"
    );
  };

  static ag_CompactFormatter = (params) => {
    return new Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(params.value);
  };

  static format_none = function (curncyVal) {
    return curncyVal;
  };
  static format_currency_usd = function (curncyVal) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(curncyVal);
  };

  static format_number = function (numberVal) {
    return new Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(numberVal);
  };

  static format_number_long = function (numberVal) {
    return new Intl.NumberFormat("en-US", {
      notation: "compact",
      compactDisplay: "long",
    }).format(numberVal);
  };

  static format_currency_short = function (curncyVal) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      compactDisplay: "short",
    }).format(curncyVal);
  };

  static format_currency_long = function (curncyVal) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      compactDisplay: "long",
    }).format(curncyVal);
  };
  static format_percent = function (numVal) {
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }).format(numVal);
  };
  static format_ratio = function (numVal) {
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(numVal / 100);
  };
  static format_nodecimal = function (numVal) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(numVal);
  };

  static TableCell = (value, formatFunction, threshold, direction, suffix) => {
    return value ? (
      direction === 1 ? (
        <Table.Cell
          key={uuid()}
          negative={value < threshold}
          positive={value > threshold}
        >
          {formatFunction(value)}
          {suffix}
        </Table.Cell>
      ) : (
        <Table.Cell
          key={uuid()}
          negative={value > threshold}
          positive={value < threshold}
        >
          {formatFunction(value)}
          {suffix}
          {suffix}
        </Table.Cell>
      )
    ) : (
      <Table.Cell key={uuid()}>-</Table.Cell>
    );
  };

  static cellColor = function (numVal) {
    if (numVal < 0) {
      return "negative";
    } else if (numVal === 0) {
      return "";
    } else {
      return "positive";
    }
  };

  static getServerURL = () => {
    if (window.location.hostname === "localhost")
      return "http://localhost:8080/ss/";
    else return "https://ssspring.herokuapp.com/ss/";
  };

  static getRecommendationColor = (recommendation) => {
    let btnColor = "red";
    if (
      recommendation === "Strong-Fundamentals" ||
      recommendation === "Good-Fundamentals"
    ) {
      btnColor = "ui green";
    } else if (recommendation === "Neutral-Fundamentals") {
      btnColor = "ui yellow";
    } else if (recommendation === "Weak-Fundamentals") {
      btnColor = "ui red";
    }
    return btnColor;
  };

  static getCellBackGroundColor = (colorString) => {
    let color = "";
    if (colorString === "green3") {
      color = "#30cc5a"; //#2fcc59";
    } else if (colorString === "green2") {
      color = "#0B610B"; // "#01DF01"; //#2ea450";
    } else if (colorString === "green1") {
      color = "#2f9e4f"; //"#dff0d8"; // "#BFFF00"; //"#A9F5BC"; //#35764e";
    } else if (colorString === "none") {
      color = "#424554";
    } else if (colorString === "red1") {
      color = "#9F5862"; //F5A9A9"; //"#9d434c";
    } else if (colorString === "red2") {
      color = "#94444d";
    } else if (colorString === "red3" || colorString === "red4") {
      color = "#a6424a";
    }
    return color;
  };
}
