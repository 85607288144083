import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, CardGroup, CardContent } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import ReactTimeAgo from "react-time-ago";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export default function CompanyNews(props) {
  const [stateValues, setstateValues] = useState({
    news: [],
    bullishPct: 0,
    bearishPct: 0,
  });

  useEffect(() => {
    console.log("CompanyNews useEffect ", props);

    let newsURL = Constants.NEWS_URL + "&tickers=" + props.symbol;
    console.log("newsURL:", newsURL);
    //let sentimentURL = Constants.SENTIMENT_URL + "&symbol=" + props.symbol;

    const fetchData = async () => {
      const newsResponse = await axios(newsURL);

      console.log("newnewsResponsesURL:", newsResponse);

      //const sentimentResponse = await axios(sentimentURL);

      //console.log("sentimentResponse:", sentimentResponse);

      setstateValues({
        news: newsResponse.data.slice(0, 10),
        /*bullishPct: sentimentResponse.data.sentiment
          ? sentimentResponse.data.sentiment.bullishPercent
          : 0,
        bearishPct: sentimentResponse.data.sentiment
          ? sentimentResponse.data.sentiment.bearishPercent
          : 0,*/
      });
    };

    fetchData();
  }, [props]);

  return (
    <>
      <CardGroup
        style={{
          marginTop: "1%",
          marginBottom: "0.2%",
          border: "none",
          boxShadow: "none",
        }}
      >
        {Object.keys(stateValues.news).map((index) => (
          <Card
            raised
            fluid
            style={{
              marginLeft: "2%",
              marginRight: "2%",
              marginTop: "-0.5%",
            }}
          >
            <CardContent>
              <Card.Header>
                <a
                  href={stateValues.news[index].url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {stateValues.news[index].title.slice(0, 100)}...
                </a>
              </Card.Header>

              <Card.Description>
                {stateValues.news[index].text}
              </Card.Description>
            </CardContent>
            <CardContent extra>
              {stateValues.news[index].site} -
              <ReactTimeAgo
                date={stateValues.news[index].publishedDate}
                locale="en-US"
              />
            </CardContent>
          </Card>
        ))}
      </CardGroup>
    </>
  );
}
