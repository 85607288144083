import React, { Component } from "react";
import { Grid, Icon, Label, Menu, Segment } from "semantic-ui-react";
import CashflowGrowthCheck from "./CashflowGrowthCheck";
import DebtCheck from "./DebtCheck";
import EPSGrowthCheck from "./EPSGrowthCheck";
import FairPriceCheck from "./FairPriceCheck";
import MoatCheck from "./MoatCheck";
import NetIncomeGrowthCheck from "./NetIncomeGrowthCheck";
import RoeCheck from "./RoeCheck";
import SalesGrowthCheck from "./SalesGrowthCheck";

export default class FACheckList extends Component {
  state = { activeItem: "sales" };

  // ** Function to tab selection
  handleItemClick = (e, { name }) => {
    //console.log("Home:handleItemClick:", name);
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;

    let seg;
    let salescolor;
    if (this.props.stockValues.stockReport.salesGrowthScore === 3) {
      salescolor = "green";
    } else if (this.props.stockValues.stockReport.salesGrowthScore === 2) {
      salescolor = "orange";
    } else {
      salescolor = "red";
    }

    let incomeColor;
    if (this.props.stockValues.stockReport.netIncomeGrowthScore === 3) {
      incomeColor = "green";
    } else if (this.props.stockValues.stockReport.netIncomeGrowthScore === 2) {
      incomeColor = "orange";
    } else {
      incomeColor = "red";
    }

    let cashflowColor;
    if (this.props.stockValues.stockReport.cashflowScore === 3) {
      cashflowColor = "green";
    } else if (this.props.stockValues.stockReport.cashflowScore === 2) {
      cashflowColor = "orange";
    } else {
      cashflowColor = "red";
    }

    let futureEpsColor;
    if (this.props.stockValues.stockReport.futureEpsScore === 3) {
      futureEpsColor = "green";
    } else if (this.props.stockValues.stockReport.futureEpsScore === 2) {
      futureEpsColor = "orange";
    } else {
      futureEpsColor = "red";
    }

    let roeColor;
    if (this.props.stockValues.stockReport.roeScore === 3) {
      roeColor = "green";
    } else if (this.props.stockValues.stockReport.roeScore === 2) {
      roeColor = "orange";
    } else {
      roeColor = "red";
    }

    let profitMarginColor;
    if (this.props.stockValues.stockReport.profitMarginScore === 3) {
      profitMarginColor = "green";
    } else if (this.props.stockValues.stockReport.profitMarginScore === 2) {
      profitMarginColor = "orange";
    } else {
      profitMarginColor = "red";
    }

    let debtColor;

    let debtScore =
      this.props.stockValues.stockReport.currentRatioScore +
      this.props.stockValues.stockReport.dsrScore +
      this.props.stockValues.stockReport.debtEqScore;
    if (debtScore === 9) {
      debtColor = "green";
    } else if (debtScore < 9 && debtScore >= 6) {
      debtColor = "orange";
    } else {
      debtColor = "red";
    }

    let ivColor;
    if (this.props.stockValues.stockReport.fairValueScore === 3) {
      ivColor = "green";
    } else if (this.props.stockValues.stockReport.fairValueScore === 2) {
      ivColor = "orange";
    } else {
      ivColor = "red";
    }

    if (activeItem === "sales") {
      seg = <SalesGrowthCheck stockValues={this.props.stockValues} />;
    } else if (activeItem === "netincome") {
      seg = <NetIncomeGrowthCheck stockValues={this.props.stockValues} />;
    } else if (activeItem === "cashflow") {
      seg = <CashflowGrowthCheck stockValues={this.props.stockValues} />;
    } else if (activeItem === "eps") {
      seg = <EPSGrowthCheck stockValues={this.props.stockValues} />;
    } else if (activeItem === "roe") {
      seg = (
        <Segment style={{ border: "none", boxShadow: "none" }}>
          <RoeCheck stockValues={this.props.stockValues} />
        </Segment>
      );
    } else if (activeItem === "moat") {
      seg = (
        <Segment style={{ border: "none", boxShadow: "none" }}>
          <MoatCheck stockValues={this.props.stockValues} />{" "}
        </Segment>
      );
    } else if (activeItem === "debt") {
      seg = (
        <Segment style={{ border: "none", boxShadow: "none" }}>
          <DebtCheck stockValues={this.props.stockValues} />
        </Segment>
      );
    } else if (activeItem === "price") {
      seg = (
        <Segment style={{ border: "none", boxShadow: "none" }}>
          <FairPriceCheck stockValues={this.props.stockValues} />
        </Segment>
      );
    }

    return (
      <Grid>
        <Grid.Row centered>
          <Menu stackable compact color="blue" icon="labeled">
            <Menu.Item
              name="sales"
              color="blue"
              active={activeItem === "sales"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={salescolor} />
              <Icon size="large" name="chart line" />
              Revenue
              <br></br>
              <br></br>
            </Menu.Item>
            <Menu.Item
              name="netincome"
              color="blue"
              active={activeItem === "netincome"}
              onClick={this.handleItemClick}
            >
              {" "}
              <Label attached="top right" size="small" color={incomeColor} />
              <Icon size="large" name="chart line" />
              Net Income
              <br></br>
              <br></br>
            </Menu.Item>
            <Menu.Item
              name="cashflow"
              color="blue"
              active={activeItem === "cashflow"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={cashflowColor} />
              <Icon size="large" name="money" />
              Cashflow
              <br></br>
              <br></br>
            </Menu.Item>

            <Menu.Item
              name="eps"
              color="blue"
              active={activeItem === "eps"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={futureEpsColor} />
              <Icon size="large" name="money" />
              EPS
              <br></br>
              <br></br>
            </Menu.Item>

            <Menu.Item
              name="moat"
              color="blue"
              active={activeItem === "moat"}
              onClick={this.handleItemClick}
            >
              <Label
                attached="top right"
                size="small"
                color={profitMarginColor}
              />
              <Icon size="large" name="angle double up" />
              Economic Moat
              <br></br>
              <br></br>
            </Menu.Item>
            <Menu.Item
              name="roe"
              color="blue"
              active={activeItem === "roe"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={roeColor} />
              <Icon size="large" name="sort amount up" />
              Consistent ROE
              <br></br>
              <br></br>
            </Menu.Item>
            <Menu.Item
              name="debt"
              color="blue"
              active={activeItem === "debt"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={debtColor} />
              <Icon size="large" name="balance scale" />
              Conservative Debt
              <br></br>
              <br></br>
            </Menu.Item>
            <Menu.Item
              name="price"
              color="blue"
              active={activeItem === "price"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={ivColor} />
              <Icon size="large" name="target" />
              Fair Price
              <br></br>
              <br></br>
            </Menu.Item>
            {/* <Menu.Item
              name="support"
              color="blue"
              active={activeItem === "support"}
              onClick={this.handleItemClick}
            >
              <Label attached="top right" size="small" color={"green"} />
              <Icon size="large" name="dollar" />
              Point of Entry
              <br></br>
              <br></br>
            </Menu.Item> */}
          </Menu>
        </Grid.Row>
        <Grid.Row centered>{seg}</Grid.Row>
      </Grid>
    );
  }
}
