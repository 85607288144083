import React from "react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import { Image, Header, Icon, Table, Divider, Card } from "semantic-ui-react";

export default function ReleaseNotes() {
  return (
    <>
      <div
        style={{
          marginLeft: "30%",
          marginRight: "30%",
        }}
      >
        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Header color="blue" textAlign="center" as="h2">
                <Icon name="newspaper" size="large" />
                Whats New (Release Updates)
              </Header>{" "}
            </Card.Description>
          </Card.Content>
        </Card>
      </div>

      <div
        style={{
          marginTop: "1%",
          justifyContent: "center",
        }}
      >
        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Table definition collapsing celled compact>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 05/14/2021</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Mobile Enhancements (Experimental)
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Very basic (minimal) functionality like searching a
                          symbol and viewing the Stock page can now be performed
                          in mobile devices using a mobile browser. For best
                          experience, access SimplyStock website in a desktop or
                          laptop computer.
                        </p>
                      </Header>
                      <Table basic="very" compact="very">
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <Image
                                src={`${process.env.PUBLIC_URL}/images/release/mobile1.jpg`}
                                size="medium"
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Image
                                src={`${process.env.PUBLIC_URL}/images/release/mobile2.jpg`}
                                size="medium"
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>

                      <br />

                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Redesigned Stock Page
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Company details main page is redesigned to include
                          stock chart (credit: TradingView)
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/new_stock_window.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Deep Dive Reports in Stock Page
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          - A new deep dive reports section is added to stock
                          main page to show (free or paid) Deep Dive Analysts
                          Reports for the underlying stock by various industry
                          experts and Analysts
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/deepdive1.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Deep Dive Reports in Tools section
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          - A new deep dive report is added under Tools -&amp;gt
                          Deep Dive Reports to show the (free or paid) Analysts
                          Reports by various industry experts and Analysts
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/deepdive2.PNG`}
                        size="medium"
                      />
                      <br />
                      <br />
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/deepdive3.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          ETF Page Enhancements (Stock Holdings)
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          -A new "Stock Holdings" tab is added to ETF Profile
                          page to show the stock reports and snapshots for the
                          all the stocks in that ETF. This will greatly help to
                          compare and analyze the ETF effectiveness.
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/etf_stock_holdings.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 03/30/2021</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Stock Window
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Company details pages are now conviniently shown in a
                          new window without leaving underlying stocks page
                          (screener, favorites, snapshots, index maps, earnings
                          calenadar etc) This new feature will allow you to
                          continue to do the research in the current page
                          without losing the context
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/stock_window.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Major Stock Holders
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          - A new hyperlink (to external website) is provided to
                          show the Institution ownership of the stock
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/institution_ownership.PNG`}
                        size="medium"
                      />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Tooltips
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          -Tool Tips are now shown on the Home Screen Index
                          maps, sector gainers and loser screens with Current
                          Price and today's price increase/decrease
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/tooltip_gainers.PNG`}
                        size="big"
                      />
                      <br />
                      <Header>
                        <p>
                          -Tool Tips are now added on the Earnings Calendar to
                          show estimated EPS value
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/tooltip_earnings.PNG`}
                        size="big"
                      />
                      <br />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Enhanced Screeners
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Screener/Investment Ideas are now enhanced with more
                          information about the screener idea or the screener.
                          Portfolios of Investment Gurus will now show the
                          images of the Guru
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/enhanced_screener.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Special Country Screeners
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          New handy country screeners are added under "Special
                          Screeners" that navigates directly to specific country
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/country_screeners_menu.PNG`}
                        size="medium"
                      />
                      <br />
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/country_screener.PNG`}
                        size="massive"
                      />
                      <br />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 01/22/2021</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          SnapShots for Screeners
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Now you can select any of your favorite screener and
                          see stocks snapshots in the screener page (in
                          Snapshots tab).
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/screener-snapshots.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Earnings Calendar
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Earnings Calendar is now avialable under 'Tools' Menu.
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/earningscal1.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          New Watchlists for 2021
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          3 New curated stock watchlists for 2021 are now
                          available under the Investment Ideas menu.
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/2021wl.PNG`}
                        size="medium"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Updates to Special Screeners
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          Several handy stock screeners are added under "Special
                          Screeners" that navigates directly to specific
                          sectors, industries
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/special_screeners.PNG`}
                        size="medium"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Screeners Performance Report
                        </Header>
                      </Divider>
                      <Header>
                        <p>
                          A new performance report is added under Tools Menu
                          that gives a performance of every available sector,
                          investment idea including your favorites stocks list
                          so you can compare and see which group of stocks or
                          sectors and performing better
                        </p>
                      </Header>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/perf_report.PNG`}
                        size="medium"
                      />

                      <br />
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/perf_report1.PNG`}
                        size="massive"
                      />
                      <br />

                      <br />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 12/04/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Favorites Enhancements
                        </Header>
                      </Divider>

                      <Header as="h3">
                        Favorites Page is enhanced with the following tabs
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/fav_menu.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance of Favorites stocks are now shown by sector
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/fav_sector.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance of Favorties are shown as a map{" "}
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/fav_map.PNG`}
                        size="massive"
                      />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Home Page Enhancements
                        </Header>
                      </Divider>
                      <Header as="h3">
                        Home Page now enhanced to show the following tabs
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/home_menu.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Top 5 Gainers and Top 5 Losers in each Major Index
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/top_gainers.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Top 5 Gainers and Top 5 Losers in each Sector
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/sector_gainers.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance of top 10 companies in each Sector
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/top10_sector.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance of Dow 30 companies in each Sector
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/dow30_sector.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance of NASDAQ 100 companies in each Sector
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/nasdaq_sector.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance of SP 500 companies in each Sector
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/sp_sector.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance Map DOW 30 companies
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/dow_map.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance Map NASDAQ 100 companies
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/nasdaq_map.PNG`}
                        size="massive"
                      />
                      <Header as="h3">
                        <br />
                        Performance Map SP 500 companies
                      </Header>

                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/sp_map.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          New Menu Item 'Specail Sectors' added
                        </Header>
                      </Divider>
                      <p>
                        A new Menu Item 'Special Sectors' is now added to bring
                        list of stocks on non traditional sectors
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/special_sector.PNG`}
                        size="small"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Sector Screeners added
                        </Header>
                      </Divider>
                      <p>
                        A new handy sector screener menu added under Specail
                        Screeners to quickly accessing the stocks in each major
                        sector
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/sector_screener.PNG`}
                        size="large"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Fund Gurus lists moved
                        </Header>
                      </Divider>
                      <p>
                        Fund Gurus Menu Item is now moved under Investment Ideas
                        as sub group name 'Gurus'
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/fund_gurus.PNG`}
                        size="large"
                      />
                      <br />
                      <br />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 10/09/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Gainers and Losers
                        </Header>
                      </Divider>
                      <p>
                        Home Page is now enhanced to show Gainers and Losers in
                        the entire US Market, Dow Jones list, S&amp;P list,
                        NASDAQ list and Russell Small Cap list. (Note:The Price
                        quotes are delayed by 15 minutes)
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/homepage.PNG`}
                        size="massive"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Browse the Index Stocks
                        </Header>
                      </Divider>
                      <p>
                        You can now click on the Index in the home page and go
                        to screener page directly. For example, clicking on Dow
                        Jones Index will take you to the Dow Jones 30 Screener
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/index.PNG`}
                        size="huge"
                      />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          NASDAQ Composite Index Screener
                        </Header>
                      </Divider>
                      <p>
                        NASDAQ Composite Index 100 Stocks screener is now added
                        to Screener Menu
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/nasdaq.PNG`}
                        size="medium"
                      />
                      <br />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Stock Upgrades/Downgrades
                        </Header>
                      </Divider>
                      <p>
                        Major Analysts upgrades and downgrades are now available
                        in the company profile page
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/stockgrade.PNG`}
                        size="huge"
                      />
                      <br />
                      <br />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 09/25/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Stock Performance
                        </Header>
                      </Divider>
                      <p>
                        Stock performance is added for several periods (1 day, 1
                        Week, 1 Month, 1 Year, YTD and from recent S&amp;P
                        Bottom) to Company and ETF Profile pages
                      </p>
                      <p style={{ textDecorationLine: "underline" }}>
                        Company Profile
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/stock_performance.PNG`}
                        size="huge"
                      />
                      <br />
                      <br />

                      <p style={{ textDecorationLine: "underline" }}>
                        ETF Profile
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/etf_performance.PNG`}
                        size="huge"
                      />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          ETF Holdings
                        </Header>
                      </Divider>
                      <p>
                        Company Profile page now shows the top 15 ETFs holding
                        this stock and the weight perentage.
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/etf_holdings.PNG`}
                        size="huge"
                      />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          ETF Country Allocations
                        </Header>
                      </Divider>
                      <p>
                        Country Allocations for an ETF is now shown in ETF
                        Profile
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/etf_country.PNG`}
                        size="huge"
                      />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Stock &amp; ETF Screener
                        </Header>
                      </Divider>
                      <p>
                        A new ETF Screener is now added to the Screner Menu.
                        Full Scrrener is renamed to 'Stock Screener'
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/etf_screener.PNG`}
                        size="large"
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 09/11/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Search Box Improvements
                        </Header>
                      </Divider>
                      <p>
                        -Search Results are improved to show the selected symbol
                        on the top
                      </p>
                      <p>
                        -Search Results now has a scroll bar and fixed height
                        for easier navigation
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/search.PNG`}
                        size="small"
                      />

                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          New Screeners
                        </Header>
                      </Divider>
                      <p>
                        -New screeners are added for S &amp; P, DowJones,
                        Russell Index companies under Screener Menu
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/sp.PNG`}
                        size="medium"
                      />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          ETFs are now available
                        </Header>
                      </Divider>
                      <p>
                        -ETFs are now searchable in the website. ETF holdings
                        breakdown by sector and top 50 holdings are showin in
                        hte ETF profile
                      </p>
                      <p>
                        -Note: Currently ETFs can not be added to favorites, and
                        they wont be included in screener results{" "}
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/etf.PNG`}
                        size="huge"
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 08/28/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          New Menu to show your favorites
                        </Header>
                      </Divider>
                      <p>
                        <b>
                          -A New Menu Item is added to show favorites as a list.
                          Very soon you will be able to add multiple favorite
                          lists
                        </b>
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/release/favorties_menu.PNG`}
                          size="huge"
                        />
                      </p>
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Performance Metrics
                        </Header>
                      </Divider>
                      <p>
                        <b>
                          -Performance Metrics are added to Investment Ideas,
                          Fund Gurus and Favorites
                        </b>
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/release/performance.PNG`}
                          size="huge"
                        />
                      </p>
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Point and Click Company name to load full details
                        </Header>
                      </Divider>
                      <p>
                        <b>
                          -Now you can click on the symbol from Screener
                          results, "Investment Ideas", "Fund Gurus", "Compare to
                          Peers", "Compare Stocks" and Favorites to directly
                          load the company details. (you will see your mouse
                          icon change to hand)
                        </b>
                      </p>
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Terms and Conditions
                        </Header>
                      </Divider>
                      <p>
                        -
                        <b>
                          Website is updated with Terms and Conditions. Please
                          read carefully
                        </b>
                      </p>
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Screener Results are now more readable
                        </Header>
                      </Divider>
                      <p>
                        -
                        <b>
                          Screener results screen column name widths are
                          adjusted to fully display its name
                        </b>
                      </p>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 07/31/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          IPO Calendar:
                        </Header>
                      </Divider>

                      <p>
                        <b>
                          - Now you can access the recent and upcoming IPOs from
                          Tools -&gt; IPO Calendar. Please note that, these
                          symbols information is not avialable in SimplyStock
                          yet
                        </b>
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/release/ipo1.PNG`}
                          size="medium"
                        />
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/release/ipo2.PNG`}
                          size="huge"
                        />
                      </p>
                      <br />
                      <br />
                      <br />
                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Analysts Recommendations:{" "}
                        </Header>
                      </Divider>

                      <p>
                        <b>
                          -Now you can find Analysts recommendations in Company
                          Profile page below the StockRadar chart
                        </b>
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/release/analyst.PNG`}
                          size="huge"
                        />
                      </p>
                      <br />
                      <br />
                      <br />

                      <Divider horizontal>
                        <Header
                          as="h1"
                          style={{ textDecorationLine: "underline" }}
                        >
                          Company News and News Sentiment
                        </Header>
                      </Divider>
                      <p>
                        <b>
                          -Latest company news can be found in the bottom
                          section of the Company Profile page (scroll down to
                          see)
                        </b>
                      </p>
                      <p>
                        <b>
                          -Sentiment Analysis is now added to company news and
                          Bullish and Bearish sentiment percentages are shown in
                          company's news section
                        </b>
                      </p>
                      <Image
                        src={`${process.env.PUBLIC_URL}/images/release/news.PNG`}
                        size="huge"
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 07/17/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        <b>-Compare Results now show Stock Radar Charts</b>
                      </p>
                      <p>
                        <b>-A new 'Favorites' feature is added to website.</b>
                      </p>
                      <p>
                        <b>
                          &emsp;&#8226;&nbsp;You can now add a stock to your
                          favorites by simply clicking on "Add to Favorites"
                          button from Comapny Page
                        </b>
                      </p>
                      <p>
                        <b>
                          &emsp;&#8226;&nbsp;If the stock is no longer a
                          favorite, you can click on "Remove from Favorites"
                          button to remove the stock from your favorites
                        </b>
                      </p>
                      <p>
                        <b>
                          &emsp;&#8226;&nbsp;You can access your favorites by
                          clicking on your name -&gt; 'Favorites' from the
                          dropdown
                        </b>
                      </p>
                      <p>
                        -You can now Signout by selecting 'Sign out' option from
                        the dropdown menu under your name
                      </p>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 06/27/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        - <b>Website is now enabled with HTTPS and SSL</b>
                      </p>
                      <p>
                        -{" "}
                        <b>
                          A radar chart is now shown in the main Company Profile
                          page that gives a glimpse of the stock's strength from
                          different fundamentals.
                        </b>
                      </p>
                      <p>
                        -{" "}
                        <b>
                          Stocck Chart is now added to "Chart" page of Company
                          Profile
                        </b>
                      </p>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 06/20/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        -{" "}
                        <b>
                          A color coded Slider is added to show the 52 Week
                          Price Range. A red slider indicates that the current
                          price is near towards its 52 week high price and green
                          color indicates that it is near to the 52 weeks low.
                        </b>
                      </p>
                      <p>
                        -{" "}
                        <b>
                          Stock Compare Results are now shown with color coded
                          values
                        </b>
                      </p>
                      <p>
                        -{" "}
                        <b>
                          Financial tab is now improved with Charts and color
                          coded values
                        </b>
                      </p>
                      <p>
                        - Company Profile page and Header page look and feel is
                        enhanced
                      </p>
                      <p>
                        - A new Tools menu is added to top navigation bar for
                        more upcoming features. Compare Stocks feature is now
                        moved under Tools menu.
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 06/13/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        -
                        <b>
                          Complete make over of the Fundamental Analysis screens
                        </b>
                      </p>
                      <p>
                        -{" "}
                        <b>Color coding is added through out the application</b>
                      </p>
                      <p>
                        <b>
                          - Tiny charts are now shown with background horizontal
                          and vertical gridlines, and in teal color now
                        </b>
                      </p>
                      <p>
                        <b>
                          - Major Holders external link is added under 'Jump to
                          Other Sites' section in Company Profile page
                        </b>
                      </p>
                      <p>
                        <b>
                          - Price changes and % price change is now shown next
                          to Current Price (Day Range)
                        </b>
                      </p>
                      <p>
                        -{" "}
                        <b>
                          Screener results now uses all the available height
                        </b>
                      </p>
                      <p>
                        - Economic Moat screen is now showing YoY growth Values
                        and charts
                      </p>
                      <p>
                        - Fundamental Analysis screens are center aligned
                        horizontally
                      </p>
                      <p>
                        - Company name is limited to first 30 characters in
                        Company Profile Page
                      </p>
                      <p>
                        - Symbol name is now shown next to the company name in
                        parenthesis
                      </p>
                      <p>
                        - Top navigation bar position is fixed, so it is always
                        stays on the top when scrolling vertically
                      </p>
                      <p>
                        - 'Hedge Fund Gurus' Menu Title is changed to 'Fund
                        Gurus'
                      </p>
                      <p>- Footer top portion is now shown in all the pages.</p>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell
                      verticalAlign="top"
                      style={{
                        backgroundColor: "#A9D0F5",
                      }}
                    >
                      <Header as="h3">Release Notes: 06/06/2020</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        -
                        <b>
                          A new 'Help' menu is added to show 'Site Help',
                          'What's New', 'Submit Feature' and 'Blog'
                        </b>
                      </p>
                      <p>
                        -
                        <b>
                          You can now search any company by typing it's Ticker
                          name or Company name
                        </b>
                      </p>
                      <p>
                        -
                        <b>
                          Now you can see the Legendary portofoilios under "Fund
                          Gurus" menu
                        </b>
                      </p>
                      <p>
                        -
                        <b>
                          Handy links are proivded in the Company Profile page
                          to jump to other financial websites
                        </b>
                      </p>
                      <p>
                        -
                        <b>
                          Company description is added on the Company Profile
                          page
                        </b>
                      </p>

                      <p>
                        - Now You can use 'Submit Feature' to request any new
                        website feature (Help - &gt; Submit Feature)
                      </p>
                      <p>
                        - You can now click on SimplyStock Logo to go back to
                        main page.
                      </p>
                      <p>
                        - First few columns are fixed (like Excel column freeze)
                        in Screener results
                      </p>
                      <p>
                        - A friendly message is shown, if there is no data
                        avaialble for a given ticker
                      </p>
                      <p>
                        - Tickers database is refined to remove ETFs and Funds
                        for now.
                      </p>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
    </>
  );
}
