import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Slider } from "react-semantic-ui-range";
import {
  Button,
  Header,
  Icon,
  Image,
  Label,
  Table,
  Card,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import Utils from "../util/Utils";
import { UserContext } from "../main/MainHome.js";

export default function CompanyHeader(props) {
  //console.log("CompanyHeader");

  const getButtonText = (isFavorite) => {
    return isFavorite ? "Remove from Favorites" : "Add to Favorites";
  };

  const initialButtonText = getButtonText(props.isFavorite);

  const userEmail = useContext(UserContext);

  /*console.log(
    "CompanyHeader props.isFavorite",
    props.isFavorite,
    "+",
    initialButtonText
  );*/

  const [buttonText, setButtonText] = useState(initialButtonText);

  //console.log("CompanyHeader buttonText", buttonText);

  useEffect(() => {
    //console.log("CompanyHeader useEffect", props.isFavorite);
    setButtonText(getButtonText(props.isFavorite));
  }, [props]);

  const handleFavorites = (buttonText, userEmail, symbol) => {
    if (buttonText === "Add to Favorites") {
      addToFavorites(userEmail, symbol);
    } else if (buttonText === "Remove from Favorites") {
      removeFromFavorites(userEmail, symbol);
    }
  };

  const addToFavorites = (userEmail, symbol) => {
    var urlVal =
      Constants.ADD_TO_FAVORITES_URL +
      "?userEmail=" +
      userEmail +
      "&symbol=" +
      symbol;

    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        alert("Symbol Added to Favorites");
        setButtonText("Remove from Favorites");
      })
      .catch(function (error) {
        alert("Unable to add Symbol to Favorites");
        console.log("Home:compStcksSubmit app response Error:", error);
      });
  };

  const removeFromFavorites = (userEmail, symbol) => {
    var urlVal =
      Constants.REMOVE_FROM_FAVORITES_URL +
      "?userEmail=" +
      userEmail +
      "&symbol=" +
      symbol;

    //console.log("removeFromFavorites:urlVal", urlVal);

    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        alert("Symbol removed from Favorites");
        setButtonText("Add to Favorites");
      })
      .catch(function (error) {
        alert("Unable to remove Symbol from Favorites");
        //console.log("Home:compStcksSubmit app response Error:", error);
      });
  };

  const companyProfile = props.stockValues.compProfile;
  const quote = props.stockValues.quote;

  let priceColor = "red";
  let iconName = "";
  if (quote.change > 0) {
    priceColor = "green";
    iconName = "triangle up";
  } else if (quote.change < 0) {
    priceColor = "red";
    iconName = "triangle down";
  } else priceColor = "black";

  const sliderSettings = {
    start: quote.price,
    min: parseFloat(quote.yearLow),
    max: parseFloat(quote.yearHigh),
    step: 1,
  };

  let sliderColor = "green";
  if (sliderSettings.start > (sliderSettings.min + sliderSettings.max) / 2) {
    sliderColor = "red";
  }

  //console.log("companyProfile.image", companyProfile.image);

  return (
    <Card raised fluid>
      <Table id="company_header" compact="very">
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header as="h4" image>
                <Image src={companyProfile.image} rounded />
                <Header.Content>
                  <a
                    href={companyProfile.website ? companyProfile.website : ""}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {companyProfile.companyName
                      ? companyProfile.companyName.substring(0, 35)
                      : ""}
                    ({companyProfile.symbol})
                  </a>
                  <Header.Subheader>
                    {companyProfile.sector
                      ? companyProfile.sector
                      : companyProfile.finVizSector}{" "}
                    {" -> "}{" "}
                    {companyProfile.industry
                      ? companyProfile.industry.substring(0, 20)
                      : companyProfile.finVizIndustry}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell width="1" textAlign="center">
              <Header as="h5">
                <Header.Content>Current Price</Header.Content>
                <Header.Subheader>
                  <Header as="h3" textAlign="center">
                    {Utils.format_currency_usd(props.stockValues.quote.price)}
                  </Header>
                </Header.Subheader>
              </Header>
            </Table.Cell>
            <Table.Cell
              width="2"
              textAlign="center"
              collapsing
              verticalAlign="middle"
            >
              <Header as="h5">
                <Header.Content>Day Change</Header.Content>
                <Header.Subheader>
                  <Header as="h3" textAlign="center" color={priceColor}>
                    <Icon name={iconName} size="small" fitted />
                    {quote.change + " "} ({quote.changesPercentage}%)
                  </Header>
                </Header.Subheader>
              </Header>
            </Table.Cell>
            <Table.Cell width="3" textAlign="center" collapsing>
              <Header as="h5">
                <Header.Content>52 Week Range</Header.Content>
                <Header.Subheader>
                  <Table celled compact="very">
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell collapsing textAlign="right">
                          ${sliderSettings.min}
                        </Table.Cell>
                        <Table.Cell>
                          <Slider
                            color={sliderColor}
                            settings={sliderSettings}
                          />
                        </Table.Cell>
                        <Table.Cell collapsing textAlign="left">
                          ${sliderSettings.max}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Header.Subheader>
              </Header>
            </Table.Cell>

            {props.stockValues.notEnoughData ? (
              <>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </>
            ) : (
              <>
                <Table.Cell width="2" textAlign="center" collapsing>
                  <Header as="h5">
                    <Header.Content>Fair Price</Header.Content>
                    <Header.Subheader>
                      <Header as="h3" textAlign="center">
                        {Utils.getFairPrice(
                          props.stockValues.stockReport.intrinsicValue
                        )}
                      </Header>
                    </Header.Subheader>
                  </Header>
                </Table.Cell>
                <Table.Cell width="2" textAlign="center" collapsing>
                  <Label
                    size="large"
                    className={Utils.getRecommendationColor(
                      props.stockValues.stockReport.recommendation
                    )}
                    tag
                  >
                    {props.stockValues.stockReport.recommendation}
                  </Label>
                </Table.Cell>

                <Table.Cell width="2" textAlign="center" collapsing>
                  <Button
                    size="small"
                    className="ui blue button"
                    onClick={() =>
                      handleFavorites(
                        buttonText,
                        userEmail,
                        companyProfile.symbol
                      )
                    }
                  >
                    <Icon name="like"></Icon> {buttonText}
                  </Button>
                </Table.Cell>
              </>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    </Card>
  );
}
