import Utils from "./Utils";

export const API_KEYS = {
  FMP_API_KEY: "apikey=8b810aa6171c1da8c17b5fec32a6b0da",
  FINHUB_API_KEY: "token=brnrl2nrh5reu6jtdjlg",
  ALPHAVANTAGE_API_KEY: "apikey=AUNVOSKPA82VLHO5",
  GOOGLE_SEARCH_API_KEY:
    "key=AIzaSyD00Q6yd8dRIy9jn1Izx1lLg3KZIybf7Yw&cx=61b0636005bdb725a",
};

const Constants = {
  FETCH_PRICES_URL: Utils.getServerURL() + "fetchPrices/",
  STORE_VALES_URL: Utils.getServerURL() + "runDailyReport/",
  COMPARE_TCKRS_URL: Utils.getServerURL() + "cmprCompTckrs/",
  SCREENER_URL: Utils.getServerURL() + "screener/",
  COATTAIL_URL: Utils.getServerURL() + "coattail/",
  SECTOR_SCREENER_URL: Utils.getServerURL() + "sector/",
  INDUSTRY_SCREENER_URL: Utils.getServerURL() + "industry/",
  COUNTRY_URL: Utils.getServerURL() + "country/",
  PERFORMANCE_URL: Utils.getServerURL() + "performance/",
  EARNINGS_CAL_URL: Utils.getServerURL() + "earningsCalendar/",
  ACTIVITY_URL: Utils.getServerURL() + "activity/",
  DEEPDIVE_URL: Utils.getServerURL() + "companyDeepdives/",
  DEEPDIVE_ALL_URL: Utils.getServerURL() + "deepdiveReport/",
  RECENT_IPOS_URL: Utils.getServerURL() + "recentIPOs/",

  SERVICE_URL: Utils.getServerURL() + "search/",
  SYMBOL_LIST_URL: Utils.getServerURL() + "symbolsList",
  VALIDATE_URL: Utils.getServerURL() + "validate/",
  ACCESS_REQUEST_URL: Utils.getServerURL() + "accessRequest/",
  FEATURE_REQUEST_URL: Utils.getServerURL() + "featureRequest/",
  ADD_TO_FAVORITES_URL: Utils.getServerURL() + "addToFavorites/",
  REMOVE_FROM_FAVORITES_URL: Utils.getServerURL() + "removeFromFavorites/",
  FAVORITES_URL: Utils.getServerURL() + "findFavorites/",
  LEADERS_URL: Utils.getServerURL() + "gainers/",
  INITIALIZE_URL: Utils.getServerURL() + "initialize",

  BLOG_URL: "https://pkkishore.wixsite.com/simplystock",

  IMAGE_URL: "https://financialmodelingprep.com/image-stock/",

  //INDEX_URL: Utils.getServerURL() + "majorIndex/",
  ETF_SECTOR_URL:
    "https://financialmodelingprep.com/api/v3/etf-sector-weightings/",

  ETF_COUNTRY_URL:
    "https://financialmodelingprep.com/api/v3/etf-country-weightings/",

  ETF_HOLDINGS_URL: "https://financialmodelingprep.com/api/v3/etf-holder/",

  INDEX_URL:
    "https://financialmodelingprep.com/api/v3/quotes/index?" +
    API_KEYS.FMP_API_KEY,

  MOST_ACTIVE_URL:
    "https://financialmodelingprep.com/api/v3/actives?" + API_KEYS.FMP_API_KEY,

  MOST_GAINERS_URL:
    "https://financialmodelingprep.com/api/v3/gainers?" + API_KEYS.FMP_API_KEY,

  MOST_LOSERS_URL:
    "https://financialmodelingprep.com/api/v3/losers?" + API_KEYS.FMP_API_KEY,

  NEWS_URL:
    "https://financialmodelingprep.com/api/v3/stock_news?" +
    API_KEYS.FMP_API_KEY,

  NEWS_URL2:
    "https://finnhub.io/api/v1/news?category=general&token=brnrl2nrh5reu6jtdjlg",

  GRADE_URL: "https://financialmodelingprep.com/api/v3/grade/",

  RECOMMENDATIONS_URL:
    "https://finnhub.io/api/v1/stock/recommendation?" + API_KEYS.FINHUB_API_KEY,

  TARGET_PRICE_URL:
    "https://finnhub.io/api/v1/stock/price-target?" + API_KEYS.FINHUB_API_KEY,

  IPO_URL: "https://finnhub.io/api/v1/calendar/ipo?" + API_KEYS.FINHUB_API_KEY,

  SENTIMENT_URL:
    "https://finnhub.io/api/v1/news-sentiment?" + API_KEYS.FINHUB_API_KEY,

  SECTOR_URL:
    "https://www.alphavantage.co/query?function=SECTOR&" +
    API_KEYS.ALPHAVANTAGE_API_KEY,

  MA_URL:
    "https://www.alphavantage.co/query?series_type=close&" +
    API_KEYS.ALPHAVANTAGE_API_KEY,
};

export default Constants;
