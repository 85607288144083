import React from "react";

export default function LoadingSpinner(props) {
  const mleft = props.mleft ? props.mleft : "50%";

  return (
    <div style={{ marginLeft: mleft, marginTop: "20%", height: "84vh" }}>
      <i className="fa fa-spinner fa-pulse fa-3x" /> Loading Details...
    </div>
  );
}
