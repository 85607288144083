import React, { Component } from "react";
import { Card, Grid, Menu, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import ChartUtils from "../charts/ChartUtils";
import "../css/home.css";
import Utils from "../util/Utils";

export default class Financials extends Component {
  state = { activeItem: "Income Statement" };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    const { color } = "orange";
    let seg;
    //console.log("financials", this.props.stockValues.incomeStmtValues);

    if (activeItem === "Income Statement") {
      seg = (
        <Grid stackable centered>
          <Grid.Row>
            <Grid.Column width={7}>
              <div>
                <Card fluid raised>
                  <Card.Content>
                    <Card.Header textAlign="center">
                      Yearly Income Statement Values{" "}
                    </Card.Header>
                    <Card.Description>
                      <Table
                        definition
                        compact="very"
                        celled
                        style={{ margin: "0 auto" }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            {Object.keys(
                              this.props.stockValues.incomeStmtValues
                            ).map((index) => (
                              <Table.HeaderCell
                                key={
                                  this.props.stockValues.incomeStmtValues[
                                    index
                                  ]["Revenue"].year
                                }
                              >
                                {this.props.stockValues.incomeStmtValues[index][
                                  "Revenue"
                                ].year.substring(0, 4)}
                              </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell>Trend</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Financials.returnRow(
                            "Income Statement",
                            this.props.stockValues.incomeStmtValues,
                            "Revenue",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.incomeStmtValues,
                            "Net Income",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.incomeStmtValues,
                            "EPS",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.incomeStmtValues,
                            "Gross Margin",
                            Utils.format_percent
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.incomeStmtValues,
                            "Profit Margin",
                            Utils.format_percent
                          )}
                        </Table.Body>
                      </Table>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              {ChartUtils.multiAxisChart(
                "Yearly",
                "Revenue vs Net Income",
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.incomeStmtValues,
                  "Revenue"
                ),
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.incomeStmtValues,
                  "Net Income"
                ),
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.incomeStmtValues,
                  "Profit Margin"
                ),
                Utils.format_number,
                Utils.format_percent
              )}

              {ChartUtils.multiAxisChart(
                "Quarterly",
                "Revenue vs Net Income",
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.quarterlyIncomeStmtValues,
                  "Revenue"
                ),
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.quarterlyIncomeStmtValues,
                  "Net Income"
                ),
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.quarterlyIncomeStmtValues,
                  "Profit Margin"
                ),
                Utils.format_number,
                Utils.format_percent
              )}
            </Grid.Column>
            <Grid.Column width={5}>
              <div style={{ float: "center" }}>
                <Card fluid raised>
                  <Card.Content>
                    <Card.Header textAlign="center">
                      Quarterly Income Statement Values
                    </Card.Header>
                    <Card.Description>
                      <Table
                        definition
                        compact="very"
                        celled
                        style={{ margin: "0 auto" }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Quarter</Table.HeaderCell>
                            {Object.keys(
                              this.props.stockValues.quarterlyIncomeStmtValues
                            ).map((index) => (
                              <Table.HeaderCell
                                key={ChartUtils.getQuarter(
                                  this.props.stockValues
                                    .quarterlyIncomeStmtValues[index]["Revenue"]
                                    .year
                                )}
                              >
                                {ChartUtils.getQuarter(
                                  this.props.stockValues
                                    .quarterlyIncomeStmtValues[index]["Revenue"]
                                    .year
                                )}
                              </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell>Trend</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Financials.returnRow(
                            "Income Statement",
                            this.props.stockValues.quarterlyIncomeStmtValues,
                            "Revenue",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.quarterlyIncomeStmtValues,
                            "Net Income",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.quarterlyIncomeStmtValues,
                            "EPS",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.quarterlyIncomeStmtValues,
                            "Gross Margin",
                            Utils.format_percent
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.quarterlyIncomeStmtValues,
                            "Profit Margin",
                            Utils.format_percent
                          )}
                        </Table.Body>
                      </Table>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (activeItem === "Balance Sheet Statement") {
      seg = (
        <Grid stackable centered>
          <Grid.Row>
            <Grid.Column width={6}>
              <div>
                {ChartUtils.stackedBarChart(
                  "Assets vs Liabilities",
                  ChartUtils.getXyDataFromValues(
                    this.props.stockValues.bsStmtValues,
                    "Total Assets"
                  ),
                  ChartUtils.getXyDataFromValues(
                    this.props.stockValues.bsStmtValues,
                    "Total Liabilities"
                  ),
                  Utils.format_number
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={10}>
              <div style={{ float: "left" }}>
                <Card fluid raised>
                  <Card.Content>
                    <Card.Header>Balance Sheet Statement Values</Card.Header>
                    <Card.Description>
                      <Table
                        definition
                        compact="very"
                        celled
                        style={{ margin: "0 auto" }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            {Object.keys(
                              this.props.stockValues.bsStmtValues
                            ).map((index) => (
                              <Table.HeaderCell
                                key={
                                  this.props.stockValues.bsStmtValues[index][
                                    "Total Assets"
                                  ].year
                                }
                              >
                                {this.props.stockValues.bsStmtValues[index][
                                  "Total Assets"
                                ].year.substring(0, 4)}
                              </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell>Trend</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Financials.returnRow(
                            "Balance Sheet",
                            this.props.stockValues.bsStmtValues,
                            "Total Assets",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.bsStmtValues,
                            "Total Liabilities",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.bsStmtValues,
                            "Total Stockholders Equity",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.bsStmtValues,
                            "Net Debt",
                            Utils.format_currency_short
                          )}
                          {Financials.returnRow(
                            "",
                            this.props.stockValues.bsStmtValues,
                            "Cash and Short Term Investments",
                            Utils.format_currency_short
                          )}
                        </Table.Body>
                      </Table>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (activeItem === "Cashflow Statement") {
      seg = (
        <Grid stackable centered>
          <Grid.Row>
            <Grid.Column width={6}>
              <div>
                {ChartUtils.singleBarChart(
                  "Operating Cashflow",
                  ChartUtils.getXyDataFromValues(
                    this.props.stockValues.cfStmtValues,
                    "Operating Cashflow"
                  ),
                  Utils.format_number
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={10}>
              <div style={{ float: "left" }}>
                <Card fluid raised>
                  <Card.Content>
                    <Card.Header>Cashflow Statement Values</Card.Header>
                    <Card.Description>
                      <Table
                        definition
                        compact="very"
                        celled
                        style={{ margin: "0 auto" }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            {Object.keys(
                              this.props.stockValues.cfStmtValues
                            ).map((index) => (
                              <Table.HeaderCell
                                key={
                                  this.props.stockValues.cfStmtValues[index][
                                    "Operating Cashflow"
                                  ].year
                                }
                              >
                                {this.props.stockValues.cfStmtValues[index][
                                  "Operating Cashflow"
                                ].year.substring(0, 4)}
                              </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell>Trend</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Financials.returnRow(
                            "Cashflow",
                            this.props.stockValues.cfStmtValues,
                            "Operating Cashflow",
                            Utils.format_currency_short
                          )}
                        </Table.Body>
                      </Table>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }

    return (
      <>
        <Grid stackable centered>
          <Grid.Row>
            <Menu color={color} compact pointing secondary>
              <Menu.Item
                name="Income Statement"
                active={activeItem === "Income Statement"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="Balance Sheet Statement"
                active={activeItem === "Balance Sheet Statement"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="Cashflow Statement"
                active={activeItem === "Cashflow Statement"}
                onClick={this.handleItemClick}
              />
            </Menu>
          </Grid.Row>
          <Grid.Row centered>{seg}</Grid.Row>
        </Grid>
      </>
    );
  }

  static returnRow = (sectionName, values, key, format_function) => {
    return (
      <Table.Row>
        <Table.Cell>{key}</Table.Cell>
        {Object.keys(values).map((index) =>
          Utils.TableCell(values[index][key].value, format_function, 0, 1)
        )}
        {ChartUtils.renderTinyLineChart(
          ChartUtils.getXyDataFromValues(values, key),
          "Value"
        )}
      </Table.Row>
    );
  };
}
