import React from "react";
import TradingViewWidget, { BarStyles } from "react-tradingview-widget";
import { Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import withWindowSize from "react-window-size";

const MiniChart = (props) => {
  return (
    <Segment style={{ border: "none", boxShadow: "none" }}>
      <TradingViewWidget
        symbol={props.symbol}
        allow_symbol_change={false}
        hide_top_toolbar={true}
        interval={"60"}
        range={"1D"}
        width={444}
        timezone="America/New_York"
        height={280}
        style={BarStyles.CANDLE}
      />
    </Segment>
  );
};
export default withWindowSize(MiniChart);
