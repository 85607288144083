import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Header } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import Utils from "../util/Utils";
import { UserContext } from "../main/MainHome.js";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";

export default function PortfolioPerformance() {
  const [portfolios, setPortfolios] = useState([]);

  const defaultColDef = { resizable: true, floatingFilter: true };
  const overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while data loading</span>';

  const userEmail = useContext(UserContext);

  const columnDefs = [
    {
      headerName: "Portfolio Name",
      field: "name",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 300,
      pinned: "left",
    },
    {
      headerName: "Category",
      field: "type",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 200,
    },

    {
      headerName: "Perf from previous Day",
      field: "perfDay",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 200,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },

    {
      headerName: "Perf to Week",
      field: "perfWeek",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to Month",
      field: "perfMonth",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to YTD",
      field: "perfYTD",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to Year",
      field: "perfYear",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to 5Y",
      field: "perfYTD5",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
    {
      headerName: "Perf to 10Y",
      field: "perfYTD10",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      valueFormatter: Utils.ag_PercentFormatter,
      cellStyle: Utils.ag_grid_cell_color_neg_val,
    },
  ];

  useEffect(() => {
    var urlVal = Constants.PERFORMANCE_URL + "?userEmail=" + userEmail;
    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        setPortfolios(response.data.portfolios);
      })
      .catch(function (error) {
        //console.log("Home:Portfolio Performance response Error:", error);
      });
  }, []);

  return (
    <>
      <div
        style={{
          marginLeft: "30%",
          marginRight: "30%",
          marginBottom: "1%",
        }}
      >
        <Card fluid raised>
          <CardContent>
            <Header color="blue" textAlign="center" as="h1">
              Portfolio Leaderboard{" "}
            </Header>
          </CardContent>
        </Card>
      </div>

      <Card fluid raised>
        <CardContent>
          <div
            className="ag-theme-quartz"
            style={{
              margin: "0.3%",
              height: "74vh",
              justifyContent: "center",
            }}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={portfolios ? portfolios : ""}
              pagination={true}
              autoSizePadding={true}
              compact={true}
              overlayLoadingTemplate={overlayLoadingTemplate}
            ></AgGridReact>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
