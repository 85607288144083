import React, { Component } from "react";
import { Accordion, Icon, Table } from "semantic-ui-react";
import Utils from "../util/Utils";

export default class IntrsicAccVal extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  loadAmounts = function (title, ai, index, amounts) {
    return (
      <>
        <Accordion.Title
          active={ai === index}
          index={index}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" /> {title}{" "}
        </Accordion.Title>
        <Accordion.Content active={ai === index}>
          <Table definition size="small" compact="very" celled>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
                {Object.keys(amounts).map((prjObj) => (
                  <Table.Cell key={amounts[prjObj].year} textAlign="center">
                    {amounts[prjObj].year}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell singleLine>Projected Amount</Table.HeaderCell>
                {Object.keys(amounts).map((prjObj) => (
                  <Table.Cell
                    key={amounts[prjObj].year + amounts[prjObj].projectedAmount}
                    textAlign="center"
                  >
                    {Utils.format_currency_usd(amounts[prjObj].projectedAmount)}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell singleLine>Discount Factor</Table.HeaderCell>
                {Object.keys(amounts).map((prjObj) => (
                  <Table.Cell
                    key={amounts[prjObj].year + amounts[prjObj].discFact}
                    textAlign="center"
                  >
                    {amounts[prjObj].discFact}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell singleLine>Discount Value</Table.HeaderCell>
                {Object.keys(amounts).map((prjObj) => (
                  <Table.Cell
                    key={amounts[prjObj].year + amounts[prjObj].discVal}
                    textAlign="center"
                  >
                    {Utils.format_currency_usd(amounts[prjObj].discVal)}
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table.Body>
          </Table>
        </Accordion.Content>
      </>
    );
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Accordion>
        {this.loadAmounts(
          " 10 Year Discounted Cashflow Projected Amounts",
          activeIndex,
          0,
          this.props.stockValues.tenDCFiv.projectedAmtList
        )}
        {this.loadAmounts(
          " 20 Year Discounted Cashflow Projected Amounts",
          activeIndex,
          1,
          this.props.stockValues.twentyDCFiv.projectedAmtList
        )}
        {this.loadAmounts(
          " 10 Year Discounted Earnings Projected Amounts",
          activeIndex,
          2,
          this.props.stockValues.tenDEiv.projectedAmtList
        )}
        {this.loadAmounts(
          " 20 Year Discounted Earnings Projected Amounts",
          activeIndex,
          3,
          this.props.stockValues.twentyDEiv.projectedAmtList
        )}
      </Accordion>
    );
  }
}
