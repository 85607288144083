import React from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from "recharts";

export default function StockRadarChart(props) {
  const getFillColor = (recommendation) => {
    let fillColor = recommendation;
    if (
      recommendation === "Good-Fundamentals" ||
      recommendation === "Strong-Fundamentals"
    ) {
      fillColor = "#52D017";
    } else if (recommendation === "Neutral-Fundamentals") {
      fillColor = "#FFE562";
    } else {
      fillColor = "#DA110D";
    }
    return fillColor;
  };

  const getFontSize = (labelName) => {
    if (props.labelSize === "long") {
      return 11;
    }
    return 7;
  };

  const getLabel = (labelName) => {
    if (props.labelSize === "long") {
      return labelName;
    } else {
      if (labelName === "Sales Growth") {
        return "Sales";
      } else if (labelName === "Earnings Growth") {
        return "Earnings";
      } else if (labelName === "Cashflow Growth") {
        return "Cash";
      } else if (labelName === "Profit Margin") {
        return "Profit";
      } else if (labelName === "Fair Value") {
        return "Value";
      } else if (labelName === "Current Ratio") {
        return "CR";
      } else if (labelName === "Debt/Equity") {
        return "Debt";
      } else if (labelName === "Future") {
        return "Futr";
      } else if (labelName === "ROE") {
        return "ROE";
      }
    }
  };

  const prepareData = (scoreData) => {
    let data = [
      {
        name: getLabel("Sales Growth"),
        score: scoreData ? scoreData.salesGrowthScore : 0,
        fullScore: 3,
      },
      {
        name: getLabel("Earnings Growth"),
        score: scoreData ? scoreData.netIncomeGrowthScore : 0,
        fullScore: 3,
      },

      {
        name: "ROE",
        score: scoreData ? scoreData.roeScore : 0,
        fullScore: 3,
      },
      {
        name: getLabel("Profit Margin"),
        score: scoreData ? scoreData.profitMarginScore : 0,
        fullScore: 3,
      },
      {
        name: getLabel("Fair Value"),
        score: scoreData ? scoreData.fairValueScore : 0,
        fullScore: 3,
      },

      {
        name: getLabel("Current Ratio"),
        score: scoreData ? scoreData.currentRatioScore : 0,
        fullScore: 3,
      },
      {
        name: getLabel("Debt/Equity"),
        score: scoreData ? scoreData.debtEqScore : 0,
        fullScore: 3,
      },
      {
        name: getLabel("Future"),
        score: scoreData ? scoreData.futureEpsScore : 0,
        fullScore: 3,
      },

      {
        name: getLabel("Cashflow Growth"),
        score: scoreData ? scoreData.cashflowScore : 0,
        fullScore: 3,
      },
    ];
    //console.log("Prepare Data", data);
    return data;
  };

  return (
    <RadarChart
      cx="52%"
      cy="50%"
      outerRadius="80%"
      width={props.width}
      height={props.height}
      data={prepareData(props.scoreData)}
    >
      <PolarGrid />
      <PolarAngleAxis
        tick={{ fontSize: getFontSize(props.labelSize) }}
        dataKey="name"
      />
      <PolarRadiusAxis angle={30} domain={[0, 3]} />
      <Radar
        name={props.scoreData ? props.scoreData.symbol : ""}
        dataKey="score"
        stroke={getFillColor(
          props.scoreData ? props.scoreData.recommendation : ""
        )}
        fill={getFillColor(
          props.scoreData ? props.scoreData.recommendation : ""
        )}
        fillOpacity={0.6}
      />
    </RadarChart>
  );
}
