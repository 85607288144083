import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Grid, Image, Table, List } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import ReactTimeAgo from "react-time-ago";
import uuid from "react-uuid";
import IndexContainer from "./IndexContainer";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export default function MainNews(props) {
  const [stateValues, setstateValues] = useState({
    news: [],
  });

  useEffect(() => {
    //console.log("MainNews useEffect ", props);

    let newsURL = Constants.NEWS_URL;
    //console.log("newsURL:", newsURL);

    const fetchData = async () => {
      try {
        const newsResponse = await axios(newsURL);

        //console.log("newsResponse:", newsResponse);

        setstateValues({
          news: newsResponse.data.slice(0, 15),
        });
      } catch (e) {
        if (!(e instanceof Error)) {
          e = new Error(e);
        }
      }
    };

    fetchData();
  }, [props]);

  return (
    <>
      <Grid
        columns={2}
        celled
        stackable
        style={{
          margin: "1%",
        }}
      >
        <Grid.Row>
          <Grid.Column width={5} compact="true">
            <IndexContainer />
          </Grid.Column>
          <Grid.Column width={11}>
            <div
              style={{
                fontSize: "125%",
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1569C7",
              }}
            >
              Latest Stock Market News
            </div>
            <List divided size="large">
              {Object.keys(stateValues.news).map((index) => (
                <List.Item key={uuid()}>
                  <Image
                    size="mini"
                    bordered
                    src={
                      Constants.IMAGE_URL +
                      stateValues.news[index].symbol +
                      ".png"
                    }
                    style={{
                      mixBlendMode: "multiply",
                    }}
                  />
                  <List.Content>
                    <div
                      style={{
                        color: "#1569C7",
                        fontSize: "105%",
                      }}
                    >
                      <a
                        href={stateValues.news[index].url}
                        rel="noopener noreferrer"
                        target="_blank"
                        textDecoration="none"
                        color="inherit"
                      >
                        {stateValues.news[index].title.slice(0, 100)}
                        ...
                      </a>
                    </div>
                    <p
                      style={{
                        color: "grey",
                      }}
                    >
                      {stateValues.news[index].site} -{" "}
                      <ReactTimeAgo
                        date={stateValues.news[index].publishedDate}
                        locale="en-US"
                      />
                    </p>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
