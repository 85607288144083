import React from "react";
import {
  HorizontalGridLines,
  LineMarkSeries,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  LineSeries,
} from "react-vis";
import { Card, Icon, Table } from "semantic-ui-react";
import Utils from "../util/Utils";

export default class ChartUtils extends React.Component {
  static findObservation = (observations, key) => {
    let obs;
    observations.filter(function (item) {
      if (item.name === key) {
        obs = item;
      }
    });
    //console.log("Obs", obs);
    return obs;
  };
  static getChartData = (xydata) => {
    let chartData = xydata.map((d) => {
      return {
        x: d.year,
        y: Number(d.value),
      };
    });
    return chartData;
  };
  static getGrowthChartData = (xydata) => {
    let chartData = xydata.map((d) => {
      return {
        x: d.year.substring(0, 4),
        y: Number(d.yoyGrowth),
      };
    });
    return chartData;
  };
  static getChartTrend = (xydata) => {
    // first element
    let first = parseFloat(xydata[0].value);
    let last = parseFloat(xydata[xydata.length - 1].value);
    // last element
    //console.log("First Element", first);
    //console.log("Last  Element", last);

    if (first > last) {
      return 0;
    } else {
      return 1;
    }
  };
  static getChartColor = (xydata) => {
    // first element
    let first = parseFloat(xydata[0].value);
    let last = parseFloat(xydata[xydata.length - 1].value);
    // last element

    if (first > last) {
      return "#9B0000";
    } else {
      return "teal";
    }
  };

  static renderExtra = (extraContent) => {
    //console.log("Extra Content", extraContent);
    if (!extraContent === "") {
      return <Card.Content extra>{extraContent}</Card.Content>;
    }
  };

  static renderTinyChart = (xydata, type) => {
    //console.log("xydata:", xydata);
    let fn = "";
    if (type === "Growth") {
      //console.log("getting growth chart data");

      fn = this.getGrowthChartData;
    } else {
      //console.log("getting value chart data");

      fn = this.getChartData;
    }
    //console.log("fn:", fn);

    let trend = this.getChartTrend(xydata);
    let chartColor = "black";
    if (trend === 1) {
      chartColor = "teal";
    } else {
      chartColor = "#9B0000";
    }
    //console.log("type", type);
    return (
      <Table.Cell negative={trend === 0} positive={trend === 1}>
        <XYPlot
          margin={{ left: 2, right: 2 }}
          xType="ordinal"
          width={75}
          height={75}
        >
          <HorizontalGridLines />
          <VerticalGridLines />

          <VerticalBarSeries color={chartColor} data={fn(xydata)} />
        </XYPlot>
      </Table.Cell>
    );
  };

  static renderTinyLineChart = (xydata, type) => {
    let fn = "";
    if (type === "Growth") {
      //console.log("getting growth chart data");

      fn = this.getGrowthChartData;
    } else {
      //console.log("getting value chart data");

      fn = this.getChartData;
    }

    let trend = this.getChartTrend(xydata);
    let chartColor = "black";
    if (trend === 1) {
      chartColor = "teal";
    } else {
      chartColor = "#9B0000";
    }

    //console.log("type", type);
    return (
      <Table.Cell negative={trend === 0} positive={trend === 1}>
        <XYPlot
          margin={{ left: 1, right: 1 }}
          xType="ordinal"
          width={50}
          height={60}
        >
          <LineSeries color={chartColor} data={fn(xydata)} />
        </XYPlot>
      </Table.Cell>
    );
  };

  static renderYoYGrowthCard = (
    title,
    xydata,
    formatfunction,
    mainObservation
  ) => {
    //console.log("MainObservation:", mainObservation);
    let trend = "";
    let extraContent = "";
    if (mainObservation != null) {
      trend = mainObservation.trend;
      extraContent = this.renderNarrative(mainObservation);
    }

    return (
      <Card raised>
        <Card.Content>
          <Card.Header>{title} Growth</Card.Header>
          <Card.Meta>{trend}</Card.Meta>
          <XYPlot
            margin={{ left: 50, right: 50 }}
            xType="ordinal"
            width={300}
            height={200}
          >
            <HorizontalGridLines />
            <VerticalGridLines />
            <XAxis title="Year" />
            <YAxis title={title} tickFormat={formatfunction} />
            <LineMarkSeries
              color={this.getChartColor(xydata)}
              data={this.getGrowthChartData(xydata)}
              style={{ strokeWidth: 1 }}
            />
          </XYPlot>
        </Card.Content>
        {this.renderExtra(extraContent)}
      </Card>
    );
  };

  static renderGrowthCard = (
    title,
    xydata,
    formatfunction,
    mainObservation
  ) => {
    //console.log("MainObservation:", mainObservation);
    let trend = "";
    let extraContent = "";
    if (mainObservation != null) {
      trend = mainObservation.trend;
      extraContent = ""; //this.renderNarrative(mainObservation);
    }

    return (
      <Card fluid raised>
        <Card.Content>
          <Card.Header>{title} Growth</Card.Header>
          <Card.Meta>{trend}</Card.Meta>
          <XYPlot
            margin={{ left: 40, right: 40 }}
            xType="ordinal"
            width={260}
            height={200}
          >
            <HorizontalGridLines />
            <VerticalGridLines />
            <XAxis title="Year" />
            <YAxis title={title} tickFormat={formatfunction} />
            <LineMarkSeries
              color={this.getChartColor(xydata)}
              data={this.getChartData(xydata)}
              style={{ strokeWidth: 1 }}
            />
          </XYPlot>
        </Card.Content>
        {this.renderExtra(extraContent)}
      </Card>
    );
  };

  static getXyDataFromValues = (values, key) => {
    //console.log("Values", values);
    const xydata = [];
    Object.keys(values).map((index) => {
      xydata.push(values[index][key]);
    });
    //console.log("xydata", xydata);
    return xydata;
  };

  static renderObservations = (title, observations) => {
    //console.log("Observations", observations);
    if (observations != null) {
      return (
        <Card fluid raised>
          <Card.Content>
            <Card.Header>Observations</Card.Header>
            <Card.Description>
              <Table id={title} definition collapsing celled compact="very">
                <Table.Body>
                  {Object.keys(observations).map((index) =>
                    this.renderNarrative(observations[index])
                  )}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      );
    }
  };
  static renderNarrative = (observation) => {
    let iconColor = "";
    let iconName = "";
    iconColor = observation.rgIndicator;

    if (observation.rgIndicator === "orange") {
      iconName = "minus circle";
    } else if (Number(observation.value) >= 10) {
      iconName = "check circle outline";
    } else if (Number(observation.value) >= 0) {
      iconName = "minus circle";
    } else {
      iconName = "remove circle";
    }

    //console.log("iconName", iconName);
    return (
      <Table.Row>
        <Table.Cell>
          <Icon name={iconName} color={iconColor} size="large"></Icon>
        </Table.Cell>
        <Table.Cell>{observation.name}</Table.Cell>
        {Utils.TableCell(observation.value, Utils.format_ratio, 0, 1)}
      </Table.Row>
    );
  };

  static singleBarChart = (title, xydata, formatfunction) => {
    const chartData1 = xydata.map((d) => {
      return {
        x: d.year.substring(0, 4),
        y: Number(d.value),
      };
    });

    let content = (
      //    return (
      <Card fluid raised>
        <Card.Content>
          <Card.Header>{title} </Card.Header>
          <XYPlot
            margin={{ left: 50, right: 50 }}
            xType="ordinal"
            width={300}
            height={200}
          >
            <XAxis />
            <YAxis tickFormat={formatfunction} />
            <VerticalBarSeries
              color={this.getChartColor(xydata)}
              data={chartData1}
              style={{ strokeWidth: 1 }}
            />
          </XYPlot>
        </Card.Content>
      </Card>
    );
    return content;
  };
  static stackedBarChart = (title, xydata1, xydata2, formatfunction) => {
    const chartData1 = xydata1.map((d) => {
      return {
        x: d.year.substring(0, 4),
        y: Number(d.value),
      };
    });

    const chartData2 = xydata2.map((d) => {
      return {
        x: d.year.substring(0, 4),
        y: Number(d.value),
      };
    });

    let content = (
      //    return (
      <Card raised>
        <Card.Content>
          <Card.Header>{title} </Card.Header>
          <XYPlot
            margin={{ left: 50, right: 50 }}
            xType="ordinal"
            width={300}
            height={200}
          >
            <XAxis />
            <YAxis tickFormat={formatfunction} />
            <VerticalBarSeries
              color="#A8E178"
              data={chartData1}
              style={{ strokeWidth: 1 }}
            />
            <VerticalBarSeries
              color="#AA2828"
              data={chartData2}
              style={{ strokeWidth: 1 }}
            />
          </XYPlot>
        </Card.Content>
      </Card>
    );
    return content;
  };
  static multiAxisChart = (
    frequency,
    title,
    xydata1,
    xydata2,
    xydata3,
    yaxis1formatfunction,
    yaxis2formatfunction,
    DiscreteColorLegend
  ) => {
    const chartData1 = xydata1.map((d) => {
      return {
        x:
          frequency === "Yearly"
            ? d.year.substring(0, 4)
            : ChartUtils.getQuarter(d.year),
        y: Number(d.value),
      };
    });

    const chartData2 = xydata2.map((d) => {
      return {
        x:
          frequency === "Yearly"
            ? d.year.substring(0, 4)
            : ChartUtils.getQuarter(d.year),
        y: Number(d.value),
      };
    });

    const chartData3 = xydata3.map((d) => {
      return {
        x:
          frequency === "Yearly"
            ? d.year.substring(0, 4)
            : ChartUtils.getQuarter(d.year),
        y: Number(d.value),
      };
    });

    let content = (
      //    return (
      <Card fluid raised>
        <Card.Content>
          <Card.Header textAlign="center">
            {title + " (" + frequency + ")"}
          </Card.Header>
          <div style={{ position: "relative" }}>
            <div>
              <XYPlot
                margin={{ left: 50, right: 50 }}
                xType="ordinal"
                width={300}
                height={200}
              >
                <XAxis />
                <YAxis tickFormat={yaxis1formatfunction} />
                <VerticalBarSeries
                  color="#A8E178"
                  data={chartData1}
                  style={{ strokeWidth: 1 }}
                />
                <VerticalBarSeries
                  color="#6495ED"
                  data={chartData2}
                  style={{ strokeWidth: 1 }}
                />
              </XYPlot>
            </div>
            <div style={{ position: "absolute", top: 0 }}>
              <XYPlot
                margin={{ left: 50, right: 50 }}
                xType="ordinal"
                width={300}
                height={200}
              >
                <YAxis orientation="right" tickFormat={yaxis2formatfunction} />
                <LineSeries color="FF8040" data={chartData3} />
              </XYPlot>
            </div>
          </div>
        </Card.Content>
      </Card>
    );
    return content;
  };
  static getQuarter = (quarterString) => {
    //2022-09
    return quarterString.substring(5, 7) + "/" + quarterString.substring(2, 4);
  };
}
