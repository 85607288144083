import React, { Component } from "react";
import { Card, Divider, Grid, Header, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import ChartUtils from "../charts/ChartUtils";
import "../css/home.css";
import Utils from "../util/Utils";

export default class EPSGrowthCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h4">
          Is Earnings Per Share (EPS) positive and growing consistently ?
        </Header>
        <Header as="h4">(10 to 15% growth is ideal)</Header>
      </Divider>
      <br />
      <Grid stackable centered>
        <Grid.Column width={3}>
          <div>
            {ChartUtils.renderGrowthCard(
              "Past EPS",
              ChartUtils.getXyDataFromValues(
                this.props.stockValues.incomeStmtValues,
                "EPS"
              ),
              Utils.format_number
            )}
            <Card raised fluid>
              <Card.Content>
                <Card.Header>Future EPS Growth</Card.Header>
                <Card.Description>
                  <Table definition compact="very">
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          Analyst's Consensus Estimate Growth
                        </Table.Cell>
                        {Utils.TableCell(
                          this.props.stockValues.compProfile.ePSnext5Y,
                          Utils.format_none,
                          10,
                          1,
                          "%"
                        )}
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card.Description>
              </Card.Content>
            </Card>
          </div>
        </Grid.Column>
        <Grid.Column width="7">
          <Card fluid raised>
            <Card.Content>
              <Card.Header>Last 5 Years Values</Card.Header>
              <Card.Description>
                <Table
                  definition
                  collapsing
                  compact="very"
                  celled
                  style={{ margin: "0 auto" }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Year</Table.HeaderCell>
                      {Object.keys(this.props.stockValues.incomeStmtValues).map(
                        (index) => (
                          <Table.HeaderCell
                            key={
                              this.props.stockValues.incomeStmtValues[index][
                                "EPS"
                              ].year
                            }
                          >
                            {this.props.stockValues.incomeStmtValues[index][
                              "EPS"
                            ].year.substring(0, 4)}
                          </Table.HeaderCell>
                        )
                      )}
                      <Table.HeaderCell>5 Year Trend</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>EPS</Table.Cell>
                      {Object.keys(this.props.stockValues.incomeStmtValues).map(
                        (index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "EPS"
                            ].value,
                            Utils.format_currency_short,
                            0,
                            1
                          )
                      )}
                      {ChartUtils.renderTinyChart(
                        ChartUtils.getXyDataFromValues(
                          this.props.stockValues.incomeStmtValues,
                          "EPS"
                        ),
                        "Value"
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>YoY Growth</Table.Cell>
                      {Object.keys(this.props.stockValues.incomeStmtValues).map(
                        (index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "EPS"
                            ].yoyGrowth,
                            Utils.format_percent,
                            0.1,
                            1
                          )
                      )}
                      {ChartUtils.renderTinyChart(
                        ChartUtils.getXyDataFromValues(
                          this.props.stockValues.incomeStmtValues,
                          "EPS"
                        ),
                        "Growth"
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              EPS is closely related to Net Profit as it is equal to Net
              Profit/Number of Shares. <br /> Ideally both should follow the
              same growth pattern, unless the company has diluted its equity by
              issuing more shares.
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={3}>
          {ChartUtils.renderObservations(
            this.props.title,
            this.props.stockValues.epsObservations
          )}
        </Grid.Column>
      </Grid>
    </>
  );
}
