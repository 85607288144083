import React from "react";
import { Card, Header } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import CompareResults from "../main/CompareResults";
import CashflowGrowthCheck from "./CashflowGrowthCheck";
import CompanyProfile from "./CompanyProfile.js";
import DebtCheck from "./DebtCheck";
import EPSGrowthCheck from "./EPSGrowthCheck";
import FairPriceCheck from "./FairPriceCheck";
import MoatCheck from "./MoatCheck";
import NetIncomeGrowthCheck from "./NetIncomeGrowthCheck";
import RoeCheck from "./RoeCheck";
import SalesGrowthCheck from "./SalesGrowthCheck";

export default function CompleteReport(props) {
  const getHeaderCard = (name) => {
    return (
      <div
        style={{
          marginTop: "1%",
          marginBottom: "1%",
        }}
      >
        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Header color="blue" textAlign="center" as="h2">
                {name}
              </Header>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
    );
  };

  return (
    <>
      {getHeaderCard("Company Profile")}
      <CompanyProfile stockValues={props.stockValues} />

      {getHeaderCard("Revenue Growth")}
      <SalesGrowthCheck stockValues={props.stockValues} />

      {getHeaderCard("Net Income Growth")}
      <NetIncomeGrowthCheck stockValues={props.stockValues} />

      {getHeaderCard("Cashflow Growth")}
      <CashflowGrowthCheck stockValues={props.stockValues} />

      {getHeaderCard("EPS Growth")}
      <EPSGrowthCheck stockValues={props.stockValues} />

      {getHeaderCard("Moat Check")}
      <MoatCheck stockValues={props.stockValues} />

      {getHeaderCard("ROE Check")}
      <div style={{ width: "70%", marginLeft: "25%" }}>
        <RoeCheck stockValues={props.stockValues} />
      </div>

      {getHeaderCard("Debt Check")}
      <div style={{ width: "70%", marginLeft: "15%" }}>
        <DebtCheck stockValues={props.stockValues} />
      </div>

      {getHeaderCard("Fair Price Check")}
      <div style={{ width: "70%", marginLeft: "15%" }}>
        <FairPriceCheck stockValues={props.stockValues} />
      </div>

      {getHeaderCard("Compare to Peers")}
      <div style={{ width: "70%", marginLeft: "15%" }}>
        <CompareResults stckLst={props.compareResults} />
      </div>
    </>
  );
}
