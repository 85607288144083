import { Component, default as React } from "react";
import "semantic-ui-css/semantic.min.css";
import { Message } from "semantic-ui-react";
import "../css/home.css";
import "../css/index.css";

export default class NoData extends Component {
  // ** Fuction for button on click

  render() {
    return (
      <div
        style={{
          width: "auto",
        }}
      >
        <div
          style={{
            marginLeft: "25%",
            width: "50%",
            textAlign: "center",
          }}
        >
          <Message negative>
            <Message.Header>
              Sorry! There is not enough data for this symbol
            </Message.Header>
          </Message>
        </div>
      </div>
    );
  }
}
